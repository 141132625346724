import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../firebase_config.js';


function MobileMenu() {
    const navigate = useNavigate();

    const menuItems = [
        { id: 'attendChkPhone', label: '출석체크', path: '/attendchkphone' },
        { id: 'attendMng', label: '출결관리', path: '/attendmng' },
        { id: 'studentMng', label: '수강생관리', path: '/studentmng' },
        { id: 'specialSchMng', label: '결석일정관리', path: '/specialschmng' },
        { id: 'scheduleMng', label: '부재일정관리', path: '/schedulemng' },
        { id: 'absenteeMng', label: '부재자관리', path: '/absenteemng' },
        { id: 'pointMng', label: '상벌점관리', path: '/pointmng' },
        { id: 'studyHistMng', label: '학습일지관리', path: '/studyhistmng' },
        { id: 'notice', label: '공지사항관리', path: '/notice' },
    ];

    const selectMenu = (menuId) => {
        const selectedItem = menuItems.find(item => item.id === menuId);
        if (selectedItem) {
          navigate(selectedItem.path);
        }
    };

    const logoutRun = async () => {
        try {
          await signOut(auth);
          navigate("/");
        } catch (error) {
          console.error("로그아웃 중 오류 발생:", error);
        }
    };


    return (
        <div className="content_wrap_mobile">
            <div className="mobile_menu_area" onClick={() => selectMenu("attendChkPhone")}>출석체크</div>
            <div className="mobile_menu_area" onClick={() => selectMenu("attendMng")}>출결관리</div>
            <div className="mobile_menu_area" onClick={() => selectMenu("studentMng")}>수강생관리</div>
            <div className="mobile_menu_area" onClick={() => selectMenu("specialSchMng")}>결석일정관리</div>
            <div className="mobile_menu_area" onClick={() => selectMenu("scheduleMng")}>부재일정관리</div>
            <div className="mobile_menu_area" onClick={() => selectMenu("absenteeMng")}>부재자관리</div>
            <div className="mobile_menu_area" onClick={() => selectMenu("pointMng")}>상벌점관리</div>
            <div className="mobile_menu_area" onClick={() => selectMenu("studyHistMng")}>학습일지관리</div>
            <div className="mobile_menu_area" onClick={() => selectMenu("notice")}>공지사항관리</div>
            <div className="mobile_menu_area logout" onClick={logoutRun}>로그아웃</div>
        </div>
    )
}

export default MobileMenu;