import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/logo_1024.png';
import LogoMobile from '../images/logo_mobile.svg';
import AlmMobile from '../images/buttons/alarm_mobile.png';

function Home() {
  const navigate = useNavigate();
  //개인정보처리방침 페이지
  const toPersonalIngo = () => {
    navigate("/personalinfo") //페이지 이동
  }
    
  return (
    <>
      <div className="content_wrap">
        <img src={Logo} alt='로고' className="home_logo"></img>
        <div className="home_logo_text">시대정신학원 출석관리시스템</div>
        <footer>
          <div className="copyright">
            <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
            <div className="personal_info" onClick={toPersonalIngo}>개인정보처리방침</div>
          </div>
        </footer>
      </div>
      {/* 태블릿 영역 */}
      <div className="content_wrap_tablet">
        <img src={Logo} alt='로고' style={{width:"27.38rem",height:"26.38rem",marginTop:"3.125rem"}}></img>
        <div style={{fontWeight:500,fontSize:"1.625rem"}}>시대정신학원 출석관리시스템</div>
        <footer>
          <div className="copyright">
            <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
            <div className="personal_info" onClick={toPersonalIngo}>개인정보처리방침</div>
          </div>
        </footer>
      </div>
      {/* 모바일 영역 */}
      <div className="content_wrap_mobile">
        <div className="home_text_mobile">시대정신학원<br />출석관리시스템</div>
        <div style={{display:'flex',justifyContent:'center'}}>
          <img src={LogoMobile} alt='로고' style={{width:"15.75rem",height:"15.75rem",marginTop:"5.81rem"}} />
        </div>
      </div>
    </>
  );
}

export default Home;