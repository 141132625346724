import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { doc, setDoc, query, collection, getDocs, deleteDoc, where, updateDoc, orderBy } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import BtnPlus from '../images/buttons/plus.png';
import BtnDownload from '../images/buttons/download.png';
import BtnUpload from '../images/buttons/upload.png';
import BtnSearch from '../images/buttons/search.png';
import BtnBlue from '../images/buttons/circle_blue.png';
import BtnEmpty from '../images/buttons/circle_empty.png';
import BtnRed from '../images/buttons/circle_red.png';
import BtnArrowDown from '../images/buttons/arrow_down_grey_16.png'
import ScheduleIcon from '../images/buttons/schedule_icon_mobile.svg';
import Downward from '../images/buttons/downward_mobile.svg';
import ArrowUpDown from '../images/buttons/arrow_updown_mobile.svg';
import SearchWhite from '../images/buttons/search_white_mobile.svg';
import Etc from '../images/buttons/etc.svg';
import EtcMod from '../images/buttons/etc_mod_mobile.svg';
import EtcDel from '../images/buttons/etc_del_mobile.svg';

function SpecialSchMng(props) {
    const navigate = useNavigate();
    const searhNameRef = useRef(null);
    const searhNameRefTablet = useRef(null);
    const searhNameRefMobile = useRef(null);
    const fileInput = useRef();

    const [studentData, setStudentData] = useState([]);
    const [absenceData, setAbsencedata] = useState([]);
    const [absenceDataRaw, setAbsencedataRaw] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState([]);
    const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
    const [excelData, setExcelData] = useState(null);
    const [upFileName, setUpFileName] = useState('');   //원본파일이름
    const [sortCriteria, setSortCriteria] = useState('seatno'); // 초기 정렬 기준은 좌석번호

    const [seasonInfo, setSeasonInfo] = useState([]);   //시즌 정보
    const [selSeason, setSelSeason] = useState("sd2");  //선택 시즌 변수

    const [textboxModal, setTextboxModal] = useState(false);
    const [currentText, setCurrentText] = useState('');
    
    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);

    const [showMenu, setShowMenu] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, right: 0 });
    const menuRef = useRef(null);

    //시즌 정보 코드
    useEffect(() => {
        async function fetchSeasonInfo() {
            const seasonQ = query(collection(db, "seasoninfo"), orderBy("seq", 'desc'));
            const seasonSnapshot = await getDocs(seasonQ);
    
            const seasonData = seasonSnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            setSeasonInfo(seasonData);
        }
    
        fetchSeasonInfo();
    }, []);
    
    const callDb = useCallback(async () => {
        //학생 데이터
        const stuq = query(collection(db, "users"), where("confirmyn", "==", "Y"), 
            where("auth", "==", 1), where("groupid", "==", selSeason)
        );
        const stusnapshot = await getDocs(stuq);
        const histq = query(collection(db, "userhistory"),where("groupid", "==", selSeason),
                    where("membertype", "==", "C"));
        const histsnapshot = await getDocs(histq); 

        const stuData = stusnapshot.docs.concat(histsnapshot.docs).map(doc => {
            const seatno = doc.data().seatno;
            const numericSeatno = seatno !== "" ? parseInt(seatno, 10) : seatno;

            return {
                ...doc.data(),
                id: doc.id,
                seatno: numericSeatno,
            };
        });

        setStudentData(stuData);

        //결석일정 데이터
        // const currentTimestamp = new Date().getTime();

        // 현재 날짜를 가져옵니다.
        const now = new Date();
        // 현재 날짜에서 1일을 뺍니다.
        now.setDate(now.getDate() - 1);
        // 시간을 23:59:59로 설정합니다.
        now.setHours(23, 59, 59, 999);
        // 타임스탬프로 변환합니다.
        const currentTimestamp = now.getTime();

        const absq = query(collection(db, "absence"), where("dayend", ">=", currentTimestamp),
                            where("groupid", "==", selSeason))
        const abssnapshot = await getDocs(absq); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
        const absData = abssnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        // stdData의 seatno 필드 데이터를 absData에 추가
        const combinedData = absData
            // 미리 승인 해줘야하는 로직이라 시작일자 현재기준보다 뒤에있는경우 안보이게 하는 필터는 일단 적용안하기.
            // .filter(item => item.daystart <= currentTimestamp)
            .map((abschedule) => {
                const matchingStudent = stuData.find((student) => student.userphone === abschedule.userphone);
                return {
                ...abschedule,
                seatno: matchingStudent ? matchingStudent.seatno : "",
                };
            });
        
        setAbsencedata(combinedData);
        setAbsencedataRaw(combinedData);

        // const filteredData = absData.filter(student => {
        //     return stuData.some(abschedule => abschedule.userphone === student.userphone);
        // });
        // setAbsencedata(filteredData);
        // setAbsencedataRaw(filteredData);

        const initialCheckboxStates = absData.map((item) => ({
            id: item.id,
            checked: false,
        }));
        setCheckboxStates(initialCheckboxStates);
        setSelectAllCheckbox(false);
    }, [selSeason])

    useEffect(() => {
        callDb();
    }, [callDb, updateState])

    const downloadTemplate = () => {
        const storage = getStorage();
        // const templateRef = ref(storage, 'documents/231114_test.xlsx');
        const templateRef = ref(storage, 'documents/specialsch_upload_template.xlsx');

        // Get the download URL
        getDownloadURL(templateRef)
        .then((url) => {
            // 만들어진 URL을 사용하여 파일을 다운로드합니다.
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'specialsch_upload_template.xlsx'); // 파일 이름 설정
            link.style.display = 'none'; // 화면에 보이지 않도록 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            console.log(error);
        });
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
        
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const dataJSON = XLSX.utils.sheet_to_json(worksheet);
        
                setExcelData(dataJSON);
            };
    
            reader.readAsArrayBuffer(file);
            setUpFileName(file.name);
        }
    }

    const stdBulkUpload = () => {
        if(selSeason === "sd2"){
            if(excelData !== null){
                let successCnt = 0;
                let failCnt = 0;
    
                //map처리 새로운 방식
                const processDocuments = async () => {
                    for (const document of excelData) {
                        if(document.이름 === "김예시" && document.일정명 === "가족여행"){
                            ++failCnt;
                        }else{
                            if(document.휴대전화번호 !== "" && document.이름 !== ""
                                && document.시작일자 !== "" && document.종료일자 !== "" 
                                && document.일정명 !== "" ){
                                
                                const phoneNumChk = document.휴대전화번호.toString().replace(/[^0-9]/g, '');
                                const startDate = document.시작일자.toString().replace(/[^0-9]/g, '');
                                const endDate = document.종료일자.toString().replace(/[^0-9]/g, '');
    
                                if(startDate.length === 8 &&
                                    endDate.length === 8 &&
                                    phoneNumChk.length === 11
                                ){
                                    const currentTimestamp = new Date().getTime();
                                    const docName = document.휴대전화번호+document.이름+currentTimestamp;
                                    const docRef = doc(db, "absence", docName)
    
                                    //시작일자
                                    const dateStringStart = startDate.toString();
                                    const yeardaystart = parseInt(dateStringStart.substring(0, 4));
                                    const monthdaystart = parseInt(dateStringStart.substring(4, 6)) - 1;
                                    const daystart = parseInt(dateStringStart.substring(6, 8));
    
                                    const datestart = new Date(yeardaystart, monthdaystart, daystart);
                                    const timestampstart = datestart.getTime();
                                    //종료일자
                                    const dateStringEnd = endDate.toString();;
                                    const yeardayend = parseInt(dateStringEnd.substring(0, 4));
                                    const monthdayend = parseInt(dateStringEnd.substring(4, 6)) - 1;
                                    const dayend = parseInt(dateStringEnd.substring(6, 8));
    
                                    const datend = new Date(yeardayend, monthdayend, dayend);
                                    const timestampend = datend.getTime();
    
                                    try{     
                                        await setDoc(docRef,{
                                            userid: "",
                                            username: document.이름,
                                            // userphone: document.휴대전화번호.toString().replace(/[^0-9]/g, ''),
                                            userphone: phoneNumChk,
                                            title: document.일정명,
                                            daystart: timestampstart,
                                            dayend: timestampend,
                                            confirmyn: "Y",
                                            regtime: currentTimestamp,
                                            groupid: "sd2",
                                        });
                                        console.log("결석일정등록완료");
                                        ++successCnt;
                                        //window.location.reload(false);  //페이지 refresh
                                    } catch(error) {
                                        console.log("에러메세지:", error.message);
                                    }
                                }else{
                                    ++failCnt;
                                }    
                            }else{
                                ++failCnt;
                            }
                        }
                    }
                }
                // map이 끝난 후에 알림을 표시
                processDocuments().then(() => {
                    Swal.fire({
                        icon: "success",
                        title: "일괄처리 완료",
                        text: "성공: "+successCnt+"건 실패: "+failCnt+"건",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            setExcelData(null);
                            fileInput.current.value = "";
                            // document.getElementById('fileInput').value = '';
                            setUpFileName("");
                            setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                        }
                    });
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    const delSchedule = (props) => {
        if(selSeason === "sd2"){
            Swal.fire({
                icon: "question",
                title: "결석일정 삭제",
                text: "삭제 하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "삭제",
                cancelButtonText: "취소",
            }).then(async (res) => {
                if (res.isConfirmed) {
                    try{
                        await deleteDoc(doc(db, "absence", props))
    
                        Swal.fire({
                            icon: "success",
                            title: "삭제 완료",
                            text: "결석일정 삭제가 완료되었습니다",
                            //showCancelButton: true,
                            confirmButtonText: "확인",
                            //cancelButtonText: "취소",
                        }).then((res) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (res.isConfirmed) {
                                //확인 요청 처리
                                setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                            }
                        });
                    }catch(error){
                        console.log(error.message);
                    }
                }
            })
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    const newModSpecialSch = (props) => {
        if(selSeason === "sd2"){
            if(props === "new"){
                navigate("/newmodspecialsch", {
                    state: {
                        colId: "new"
                    },
                })
            }else{
                navigate("/newmodspecialsch", {
                    state: {
                        colId: props
                    },
                })
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    //이름조회 input 처리로직
    const handleSearch = (props) => {
        const nameValue = props;
        setAbsencedata(absenceDataRaw);
        
        if(nameValue !== ""){
            const filteredCustomer = absenceDataRaw.filter(item => item.username.includes(nameValue));
            setAbsencedata(filteredCustomer);
        }else{
            callDb();
        }
    }

    //체크박스 선택로직
    const handleCheckboxChange = (id) => {
        const updatedCheckboxStates = checkboxStates.map((item) =>
            item.id === id ? { ...item, checked: !item.checked } : item
        );
        setCheckboxStates(updatedCheckboxStates);
        setAbsencedata((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, selected: !row.selected } : row
            )
        );
    }

    const handleSelectAllCheckboxChange = () => {
        setSelectAllCheckbox(!selectAllCheckbox);
        const updatedCheckboxStates = checkboxStates.map((item) => ({
            ...item,
            checked: !selectAllCheckbox,
        }));
        setCheckboxStates(updatedCheckboxStates);
        setAbsencedata((prevRows) =>
            prevRows.map((row) => ({ ...row, selected: !selectAllCheckbox }))
        );
    };

    const delSelectedRow = () => {
        if(selSeason === "sd2"){
            const selectedIds = absenceData.filter(row => row.selected === true);

            if(selectedIds.length === 0){
                Swal.fire({
                    icon: "error",
                    title: "결석일정 삭제",
                    text: "선택된 일정이 없습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                })
            }else{
                Swal.fire({
                    icon: "question",
                    title: "결석일정 삭제",
                    text: "선택된 일정을 삭제하겠습니까?",
                    showCancelButton: true,
                    confirmButtonText: "삭제",
                    cancelButtonText: "취소",
                }).then((res) => {
                    if (res.isConfirmed) {
                        try{
                            selectedIds.map(async (row) => {
                                await deleteDoc(doc(db, "absence", row.id))
                            })
                            Swal.fire({
                                icon: "success",
                                title: "결석일정 삭제",
                                text: "일정 삭제가 완료되었습니다",
                                //showCancelButton: true,
                                confirmButtonText: "확인",
                                //cancelButtonText: "취소",
                            }).then((res) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (res.isConfirmed) {
                                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                }
                            });
                        }
                        catch(error){
                            console.log(error);
                        }
                    }
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    // 파일태그 클릭효과
    const onClickContentUpload = () => {
        fileInput.current.click();
    }

    function formatPhoneNumber(phoneNumber) {
        // 정규식을 사용하여 숫자만 추출
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
        // 전화번호 형식으로 변환
        const formatted = cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    
        return formatted;
    }

    //체크박스 선택한 항목 승인로직
    const handleConfirm = (props) => {
        if(selSeason === "sd2"){
            const selectedIds = absenceData.filter(row => row.selected === true);
            let confirmYn;

            if(props === "Y"){
                confirmYn = "승인";
            }else{
                confirmYn = "해제";
            }
    
            if(selectedIds.length === 0){
                Swal.fire({
                    icon: "error",
                    // title: "수강생 삭제",
                    text: "선택된 결석일정이 없습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                })
            }else{
                Swal.fire({
                    icon: "question",
                    title: `선택 ${confirmYn}`,
                    text: `선택한 결석일정 ${confirmYn}처리 하시겠습니까?`,
                    showCancelButton: true,
                    confirmButtonText: `${confirmYn}`,
                    cancelButtonText: "취소",
                }).then((res) => {
                    if (res.isConfirmed) {
                        try{
                            selectedIds.map(async (row) => {
                                await updateDoc(doc(db, "absence", row.id), {
                                    confirmyn: props,
                                });
                            })
                            
                            Swal.fire({
                                icon: "success",
                                title: `${confirmYn}처리 완료`,
                                // text: "",
                                //showCancelButton: true,
                                confirmButtonText: "확인",
                                //cancelButtonText: "취소",
                            }).then((res) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (res.isConfirmed) {
                                    //확인 요청 처리
                                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                }
                            });
                        } catch(error){
                            console.log(error.message);
                        }
                    }
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    };

    const handleEtcClick = (event, docId) => {
        event.preventDefault();
        const rect = event.target.getBoundingClientRect();
        setMenuPosition({
            top: rect.bottom + window.scrollY,
            right: window.innerWidth - rect.right + window.scrollX
        });
        setShowMenu(docId);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const handleSortClick = (criteria) => {
        setSortCriteria(criteria);
    };

    const handleViewTextboxClick = (text) => {
        setCurrentText(text);
        setTextboxModal(true);
    };

    const handleCloseTextboxModal = () => {
        setTextboxModal(false);
        setCurrentText('');
    };

    return (
        <>
            <div className="content_wrap">
                <div style={{width:"100%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{width:"40%",color:"#191919",fontWeight:"500",fontSize:"1.375rem"}}>결석일정관리</div>
                    <div style={{display:'flex'}}>
                        <div className="btn_upside" style={{width:"6.375rem",height:"2.5rem"}}>
                            <img src={BtnPlus} className="btn_icon" style={{marginLeft:"0.625rem"}} alt='신규'></img>
                            <div className="btn_text" style={{color:"#0F5EFE"}}
                                onClick={()=>newModSpecialSch("new")}
                            >신규생성</div>
                        </div>
                        <div className="btn_upside" style={{width:"9.25rem",height:"2.5rem",marginLeft:"0.9375rem"}}>
                            <img src={BtnDownload} className="btn_icon" style={{marginLeft:"0.9375rem"}} alt='다운로드'></img>
                            <div className="btn_text" style={{color:"#191919"}}
                                onClick={downloadTemplate}
                            >템플릿 다운로드</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',width:"21.25rem",height:"2.5rem",
                                    borderRadius:"3.125rem",backgroundColor:"#F4F4F4",marginLeft:"0.9375rem"}}>
                            <div className="btn_upside" style={{width:"5.938rem",height:"2.5rem"}}>
                                <img src={BtnUpload} className="btn_icon" style={{marginLeft:"0.8125rem"}} alt='업로드'></img>
                                <div className="btn_text" style={{color:"#191919"}}
                                    onClick={()=>stdBulkUpload()}
                                >업로드</div>
                            </div>
                            <input 
                                type="file"
                                ref={fileInput}
                                style={{display:"none"}}
                                onChange={handleFileUpload}
                            ></input>
                            <input
                                style={{color:"#A6A7AB",backgroundColor:"#F4F4F4"}}
                                placeholder="첨부파일" 
                                defaultValue={upFileName}
                                readOnly
                            >
                            </input>
                            <div className="btn_upside" style={{width:"5.313rem",height:"2.5rem",justifyContent:'center'}}>
                                <div style={{color:"#949494",fontWeight:400,fontSize:"0.8125rem"}}
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        onClickContentUpload();
                                    }}
                                >파일선택</div>
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                            <input name="search_input" type="text" placeholder="이름" ref={searhNameRef}
                                style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                            />
                            <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                                onClick={() => {handleSearch(searhNameRef.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{width:"100%",marginTop:"4.375rem",marginBottom:"1.25rem",display:'flex',justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                        <div className="btn_midside" style={{width:"4.25rem"}} onClick={()=>handleConfirm("Y")}>
                            <img src={BtnBlue} className="btn_midicon" alt='승인' />
                            승인
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem"}} onClick={()=>handleConfirm("N")}>
                            <img src={BtnEmpty} className="btn_midicon" alt='승인해제' />
                            승인해제
                        </div>
                        <div className="btn_midside" style={{width:"4.25rem"}} 
                            onClick={()=>delSelectedRow()}
                        >
                            <img src={BtnRed} className="btn_midicon" alt='삭제' />
                            삭제
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                                onChange={(e) => {
                                    setSelSeason(e.target.value);
                                }}>
                            {seasonInfo.map(season => (
                                <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                            ))}
                        </select>
                        <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                            <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                            좌석번호순
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem",marginRight:"0rem"}} onClick={() => handleSortClick('username')}>
                            <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                            이름순
                        </div>
                    </div>
                </div>
                <div style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <input
                            name="selectAllCheckbox"
                            type="checkbox"
                            style={{width:"0.8125rem",marginRight:"1.25rem"}}
                            checked={selectAllCheckbox}
                            onChange={handleSelectAllCheckboxChange}
                        />
                    </div>
                    <div style={{width:"4.375rem"}}>이름</div>
                    <div style={{width:"4.375rem"}}>좌석번호</div>
                    <div style={{width:"9.375rem"}}>전화번호</div>
                    <div style={{width:"12.5rem"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"9.375rem"}}>일정명</div>
                    <div style={{width:"14.38rem"}}>기간</div>
                    <div style={{width:"3.75rem"}}>승인여부</div>
                    <div style={{width:"7.5rem"}}/>
                </div>
                {absenceData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'username') {
                            // 이름을 문자열로 비교
                            return a.username.localeCompare(b.username);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        const userPhone = formatPhoneNumber(doc.userphone);
                        
                        const startScheDate = new Date(doc.daystart);
                        const startYear = startScheDate.getFullYear().toString();
                        const startMonth = (startScheDate.getMonth()+1).toString();
                        const startDay = startScheDate.getDate().toString();
                        const formattedDateStart = startYear+"/"+startMonth+"/"+startDay;

                        const endScheDate = new Date(doc.dayend);
                        const endYear = endScheDate.getFullYear().toString();
                        const endMonth = (endScheDate.getMonth()+1).toString();
                        const endDay = endScheDate.getDate().toString();
                        const formattedDateEnd = endYear+"/"+endMonth+"/"+endDay;

                        const period = formattedDateStart+" ~ "+formattedDateEnd;

                        let gender = "";
                        let userInfo = "";
                        let seatNo = "";
                        const student = studentData.filter(item => item.userphone === doc.userphone);
                        if(student.length !== 0){
                            if(student[0].gender === "M"){
                                gender = "남";
                            }else if(student[0].gender === "F"){
                                gender = "여";
                            }else{
                                gender = "";
                            }
                            userInfo = student[0].school+" / "+student[0].grade+" / "+gender;
                            seatNo = student[0].seatno
                        }

                        return (
                            <div key={doc.id} style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <input name="body_checkbox" 
                                        type="checkbox"
                                        style={{width:"0.8125rem",marginRight:"1.25rem"}}
                                        checked={checkboxStates.find((c) => c.id === doc.id)?.checked || false}
                                        onChange={() => handleCheckboxChange(doc.id)}
                                    />
                                </div>
                                <div style={{width:"4.375rem",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"4.375rem"}}>{seatNo}</div>
                                <div style={{width:"9.375rem"}}>{userPhone}</div>
                                <div style={{width:"12.5rem"}}>{userInfo}</div>
                                <div style={{width:"9.375rem",cursor:'pointer'}} title={doc.title}
                                    onClick={() => handleViewTextboxClick(doc.title)}
                                >
                                    {doc.title.length > 10 ? doc.title.slice(0, 10) + '...' : doc.title}
                                </div>
                                <div style={{width:"14.38rem"}}>{period}</div>
                                <div style={{width:"3.75rem",color:doc.confirmyn === "Y" ? "#0F5EFE" : "#D9D9D9"}}>{doc.confirmyn}</div>
                                <div style={{width:"7.5rem",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>newModSpecialSch(doc.id)}>수정</button>
                                    <button className="btn_moddel" style={{color:"#FF594F"}} onClick={()=>delSchedule(doc.id)}>삭제</button>
                                </div>
                            </div>
                        );
                    }
                )}
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{width:"40%",color:"#191919",fontWeight:"500",fontSize:"1.25rem"}}>결석일정관리</div>
                    <div style={{display:'flex'}}>
                        <div className="btn_upside" style={{width:"2.5rem",height:"2.5rem",marginRight:"0.625rem"}}>
                            <img src={BtnPlus} className="btn_icon" alt='신규'
                                onClick={()=>newModSpecialSch("new")}>
                            </img>
                        </div>
                        {/* <div className="btn_upside" style={{width:"40px",height:"40px"}}>
                            <img src={BtnDownload} className="btn_icon" alt='다운로드'
                                onClick={downloadTemplate}>
                            </img>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',width:"248px",height:"40px",
                                    borderRadius:"50px",backgroundColor:"#F4F4F4",marginLeft:"10px"}}>
                            <div className="btn_upside" style={{width:"40px",height:"40px"}}>
                                <img src={BtnUpload} className="btn_icon" alt='업로드'
                                    onClick={stdBulkUpload}>
                                </img>
                            </div>
                            <input 
                                type="file"
                                ref={fileInput}
                                style={{display:"none"}}
                                onChange={handleFileUpload}
                            ></input>
                            <input
                                style={{color:"#A6A7AB",backgroundColor:"#F4F4F4",width:"130px"}}
                                placeholder="첨부파일" 
                                defaultValue={upFileName}
                                readOnly
                            >
                            </input>
                            <div className="btn_upside" style={{width:"66px",height:"40px",justifyContent:'center'}}>
                                <div style={{color:"#949494",fontWeight:400,fontSize:"13px"}}
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        onClickContentUpload();
                                    }}
                                >파일</div>
                            </div>
                        </div> */}
                        <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                            <input name="search_input" type="text" placeholder="이름" ref={searhNameRefTablet}
                                style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                            />
                            <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefTablet.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"4.375rem",marginBottom:"1.25rem",display:'flex',justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                        <div className="btn_midside" style={{width:"4.25rem"}} onClick={()=>handleConfirm("Y")}>
                            <img src={BtnBlue} className="btn_midicon" alt='승인' />
                            승인
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem"}} onClick={()=>handleConfirm("N")}>
                            <img src={BtnEmpty} className="btn_midicon" alt='승인해제' />
                            승인해제
                        </div>
                        <div className="btn_midside" style={{width:"4.25rem"}} 
                            onClick={()=>delSelectedRow()}
                        >
                            <img src={BtnRed} className="btn_midicon" alt='삭제' />
                            삭제
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                            onChange={(e) => {setSelSeason(e.target.value);}}>
                            {seasonInfo.map(season => (
                                <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                            ))}
                        </select>
                        <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                            <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                            좌석번호순
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem",marginRight:"0rem"}} onClick={() => handleSortClick('username')}>
                            <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                            이름순
                        </div>
                    </div>
                </div>
                <div style={{width:"95%",height:"3rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"1rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <input
                            name="selectAllCheckbox"
                            type="checkbox"
                            style={{width:"0.8125rem",marginRight:"1.25rem"}}
                            checked={selectAllCheckbox}
                            onChange={handleSelectAllCheckboxChange}
                        />
                    </div>
                    <div style={{width:"13%"}}>이름</div>
                    <div style={{width:"10%"}}>좌석번호</div>
                    <div style={{width:"20%"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"15%"}}>일정명</div>
                    <div style={{width:"20%"}}>기간</div>
                    <div style={{width:"10%"}}>승인여부</div>
                    <div style={{width:"10%"}}/>
                </div>
                {absenceData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'username') {
                            // 이름을 문자열로 비교
                            return a.username.localeCompare(b.username);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        // const userPhone = formatPhoneNumber(doc.userphone);
                        
                        const startScheDate = new Date(doc.daystart);
                        const startYear = startScheDate.getFullYear().toString();
                        const startMonth = (startScheDate.getMonth()+1).toString();
                        const startDay = startScheDate.getDate().toString();
                        const formattedDateStart = startYear+"/"+startMonth+"/"+startDay;

                        const endScheDate = new Date(doc.dayend);
                        const endYear = endScheDate.getFullYear().toString();
                        const endMonth = (endScheDate.getMonth()+1).toString();
                        const endDay = endScheDate.getDate().toString();
                        const formattedDateEnd = endYear+"/"+endMonth+"/"+endDay;

                        const period = formattedDateStart+" ~ "+formattedDateEnd;

                        let gender = "";
                        let userInfo = "";
                        let seatNo = "";
                        const student = studentData.filter(item => item.userphone === doc.userphone);
                        if(student.length !== 0){
                            if(student[0].gender === "M"){
                                gender = "남";
                            }else if(student[0].gender === "F"){
                                gender = "여";
                            }else{
                                gender = "";
                            }
                            userInfo = student[0].school+" / "+student[0].grade+" / "+gender;
                            seatNo = student[0].seatno
                        }
                        return (
                            <div key={doc.id} style={{width:"95%",height:"3rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:500,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <input name="body_checkbox" 
                                        type="checkbox"
                                        style={{width:"0.8125rem",marginRight:"1.25rem",display:'flex',alignItems:'center'}}
                                        checked={checkboxStates.find((c) => c.id === doc.id)?.checked || false}
                                        onChange={() => handleCheckboxChange(doc.id)}
                                    />
                                </div>
                                <div style={{width:"13%",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"10%"}}>{seatNo}</div>
                                <div style={{width:"20%"}}>{userInfo}</div>
                                <div style={{width:"15%",cursor:'pointer'}} title={doc.title}
                                    onClick={() => handleViewTextboxClick(doc.title)}
                                >
                                    {doc.title.length > 10 ? doc.title.slice(0, 10) + '...' : doc.title}
                                </div>
                                <div style={{width:"20%"}}>{period}</div>
                                <div style={{width:"10%",color:doc.confirmyn === "Y" ? "#0F5EFE" : "#D9D9D9"}}>{doc.confirmyn}</div>
                                <div style={{width:"10%",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>newModSpecialSch(doc.id)}>수정</button>
                                    <button className="btn_moddel" style={{color:"#FF594F"}} onClick={()=>delSchedule(doc.id)}>삭제</button>
                                </div>
                            </div>
                        )
                    })
                }
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title studentmng">
                    <div style={{display:'flex',alignItems:'center'}}>
                        <img src={ScheduleIcon} alt='결석일정관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                        <div>결석일정관리</div>
                    </div>
                    <button className="add_student">
                        <img src={BtnPlus} alt='신규'onClick={()=>newModSpecialSch("new")} />
                    </button>
                </div>
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'3.13rem'}}>
                    <div style={{display:'flex'}}>
                        <div className="button_season_sort">
                            <select className="season" 
                                onChange={(e) => {setSelSeason(e.target.value);}}>
                                {seasonInfo.map(season => (
                                    <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                                ))}
                            </select>
                            <img src={Downward} alt='시즌' style={{width:"1rem",height:"1rem"}} />
                        </div>
                        <div style={{width:'0.62rem'}} />
                        <div className="button_season_sort" 
                            onClick={() => {
                                if(sortCriteria === "seatno"){
                                    handleSortClick('username')
                                }else{
                                    handleSortClick('seatno')
                                }
                            }}
                        >
                            {sortCriteria === "seatno" ? "좌석순"
                            : "이름순"
                            }
                            <img src={ArrowUpDown} alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        </div>
                    </div>
                    <div className="search_name_area">
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefMobile}/>
                        <div className="button_area">
                            <img src={SearchWhite} style={{width:'0.9375rem',height:'0.99613rem'}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefMobile.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',marginTop:'1.5rem'}}>
                    <button className="manage_area approve" onClick={()=>handleConfirm("Y")}>승인</button>
                    <button className="manage_area approve_cancel" onClick={()=>handleConfirm("N")}>승인해제</button>
                    <button className="manage_area del" onClick={()=>delSelectedRow()}>삭제</button>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="checkbox" />
                        <div className="name">이름</div>
                        <div className="seat">좌석</div>
                        <div className="phone">전화번호</div>
                        <div className="schedule">일정명</div>
                        <div className="schedule_term">기간</div>
                        <div className="approve">승인</div>
                    </div>
                    {absenceData
                        .sort((a, b) => {
                            if (sortCriteria === 'seatno') {
                                // 좌석번호 숫자를 비교
                                if (a.seatno !== "" && b.seatno !== "") {
                                    return a.seatno - b.seatno;
                                } else if (a.seatno === "") {
                                    return 1; // null은 뒤로 정렬
                                } else {
                                    return -1; // null은 뒤로 정렬
                                }
                            } else if (sortCriteria === 'username') {
                                // 이름을 문자열로 비교
                                return a.username.localeCompare(b.username);
                            }
                            // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                            return 0;
                        })
                        .map((doc) => {
                            const userPhone = formatPhoneNumber(doc.userphone);
                            
                            const startScheDate = new Date(doc.daystart);
                            const startYear = startScheDate.getFullYear().toString();
                            const startMonth = (startScheDate.getMonth()+1).toString();
                            const startDay = startScheDate.getDate().toString();
                            const formattedDateStart = startYear+"/"+startMonth+"/"+startDay;

                            const endScheDate = new Date(doc.dayend);
                            const endYear = endScheDate.getFullYear().toString();
                            const endMonth = (endScheDate.getMonth()+1).toString();
                            const endDay = endScheDate.getDate().toString();
                            const formattedDateEnd = endYear+"/"+endMonth+"/"+endDay;

                            const period = formattedDateStart+" ~ "+formattedDateEnd;

                            let seatNo = "";
                            const student = studentData.filter(item => item.userphone === doc.userphone);
                            if(student.length !== 0){
                                seatNo = student[0].seatno
                            }
                            return (
                                <div className="body_area_mobile" key={doc.id}>
                                    <input name="body_checkbox" 
                                        type="checkbox"
                                        style={{width:"1rem",marginRight:"0.62rem"}}
                                        checked={checkboxStates.find((c) => c.id === doc.id)?.checked || false}
                                        onChange={() => handleCheckboxChange(doc.id)}
                                    />
                                    <div className="name">{doc.username}</div>
                                    <div className="seat">
                                        <div className="seatno">{seatNo}</div>
                                    </div>
                                    <div className="phone">{userPhone}</div>
                                    <div className="schedule" title={doc.title} onClick={() => handleViewTextboxClick(doc.title)}>
                                        {doc.title.length > 10 ? doc.title.slice(0, 10) + '...' : doc.title}
                                    </div>
                                    <div className="schedule_term">{period}</div>
                                    <div className="approve" style={{color:doc.confirmyn === "Y" ? "#0F5EFE" : "#D9D9D9"}}>{doc.confirmyn}</div>
                                    <img src={Etc} alt='더보기' style={{width:'2.5rem',height:'2.5rem'}} 
                                        onClick={(e) => handleEtcClick(e, doc.id)}
                                    />
                                    {showMenu === doc.id && (
                                        <div 
                                            ref={menuRef}
                                            className="etc_area"
                                            style={{
                                                position: 'absolute',
                                                top: `${menuPosition.top}px`,
                                                right: `${menuPosition.right}px`,
                                                zIndex: 1000,
                                            }}
                                        >
                                            <button
                                                className="del mod"
                                                onClick={() => {
                                                    newModSpecialSch(doc.id)
                                                    setShowMenu(null);
                                                }}
                                            >
                                                <img src={EtcMod} alt='수정' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod">수정</div>
                                            </button>
                                            <button 
                                                className="del"
                                                onClick={() => {
                                                    delSchedule(doc.id);
                                                }}
                                            >
                                                <img src={EtcDel} alt='삭제' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod del">삭제</div>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {textboxModal && (
                // 모달영역 바깥 부분
                <div
                    onClick={handleCloseTextboxModal}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.1)',
                        zIndex: 999,
                    }}
                />
            )}
            {textboxModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "31.25rem",
                        height: "16.88rem",
                        background: '#FFF',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        borderRadius: "1rem",
                        padding: "1.25rem",
                        zIndex: 1000,
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-between'
                    }}
                >
                    <textarea
                        value={currentText}
                        readOnly
                        style={{
                            width: '100%',
                            height: '90%',
                            fontSize: "0.875rem",
                            padding: "0.625rem",
                            borderRadius: "0.5rem",
                            borderColor: '#E8E8E8',
                            resize: 'none',
                            marginBottom: "1.25rem",
                            cursor: 'default',
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            onClick={handleCloseTextboxModal}
                            style={{
                                background: '#EFEFEF',
                                color: '#373737',
                                borderRadius: "0.5rem",
                                padding: '0.625rem 1.25rem',
                                marginRight: "0.625rem",
                                fontSize:"0.875rem",
                                fontWeight:500
                            }}
                        >닫기</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default SpecialSchMng;