import React, { useState, useEffect } from 'react';
import { db } from '../firebase_config.js';
import { doc, setDoc, getDocs, updateDoc, where, collection, query } from "firebase/firestore";
import Swal from "sweetalert2";
import BtnDel from '../images/buttons/delete.png';
import ImgSuccess from '../images/buttons/alarm_attndnc.png';
// import { sendSms } from '../SmsManager.js';

function AttendChkPhone() {
  const [studentData, setStudentData] = useState("");
  const [timeData, setTimeData] = useState("");
  const [input, setInput] = useState([]);
  const [isDivVisible, setDivVisible] = useState(false);  //저장완료시 뜨는 문구
  const [selectedDiv, setSelectedDiv] = useState(null);

  useEffect(() => {
      if (isDivVisible) {
          const timer = setTimeout(() => {
          setDivVisible(false);
          }, 1000);

          return () => {
          clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 정리
          };
      }
  },[isDivVisible])

  //저장완료됐을때 저장완료 div 보이게 하는 로직
  const handleSaveComplete = () => {
      setDivVisible(true);
  };
  
  const sendPushMessage = async (props) => {
    try {
      await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          Authorization: 'key=AAAANosSmKo:APA91bFttuJQY_oGWqvF-WoeOW1X3-rmmrY7T7QldVYzAYO1c3TQo-JK22aYfSeh-_K4UoRNFnm5oO-Yqnj-qZFIOGnXbYxGiXps5SR82pv8l_BsyP5OPwdHXpWzKNuy3nw1J11RITOj', // Firebase 콘솔에서 얻은 서버 키
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          to: props.token,
          notification: props.notification
        })
      });
      console.log('푸시 메시지를 성공적으로 보냈습니다.');
    } catch (error) {
      console.error('푸시 메시지 보내기 실패:', error);
    }
  }

  const registerWithPhone = async () => {
    const now = new Date(); // 현재 날짜 및 시간 가져오기
    const year = now.getFullYear(); // 연도
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
    const day = String(now.getDate()).padStart(2, '0'); // 일
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    // const nowChkDate = `${year}${month}${day}`;

    if(input.length > 0) {
      const combinedPhone = '010'+input.join('');
      const q = query(collection(db, "users"), where("userphone", "==", combinedPhone));
      const querySnapshot = await getDocs(q); //students 테이블의 stdcardno 중에 태그한 카드값이 있는지;
      const userData = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
      }));

      if (querySnapshot.docs.length !== 0) {
        const attRef = collection(db, "attendance");
        const q = query(attRef, where("stdphone", "==", combinedPhone), where("completeyn", "==", "N"));
        const qSnapshot = await getDocs(q); //attendance 테이블의 오늘 출석한 stdcardno 중에 태그한 카드값이 있는지;
      
        if (qSnapshot.docs.length !== 0) {
          const attData = qSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
          }));

          const elapsedMin = Math.round((now.getTime() - attData[0].entertime.toDate())/1000/60);
          console.log("측정시간: ", elapsedMin);

          if(elapsedMin > 0 && attData[0].exittime === ""){
            try{
                const docRef = doc(db, "attendance", attData[0].id);
                await updateDoc(docRef, {
                    completeyn: 'Y',
                    exittime: now,
                    exittimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분"
                });
                setStudentData(attData[0].stdname+"님 퇴실완료");
                setTimeData(year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분")
              } catch(error){
                console.log(error.message);
            }

            //push알람 로직
            const payload = {
              notification: {
                title: '출석정보',
                body: month+"월 "+day+"일 "+hours+"시 "+minutes+"분 퇴실완료",
              },
              token: userData[0].token,
            };

            sendPushMessage(payload);

            //부모에게 push 알람 발신
            createPayload(userData[0].username, userData[0].userphone, userData[0].parentphone, "exit")
              .then(payload => {
                sendPushMessage(payload);
              })
              .catch(error => {
                console.error('payload 생성 중 오류 발생:', error);
              })

            //sms 발신 로직
            // const smsPayload = {
            //   phone: userData[0].parentphone,
            //   content: `[시대정신학원]\n${userData[0].username}님 ${month}월 ${day}일 ${hours}시 ${minutes}분 [퇴실] 완료`,
            // };
            // 부모에게 문자발신 대신 푸시메세지로 보내기로 함;
            // sendSms(smsPayload.phone, smsPayload.content);
            setInput([]);
            handleSaveComplete();
          }else{
            Swal.fire({
                icon: "error",
                title: "출결 실패",
                text: "입실 1분 후 부터 퇴실 가능합니다.",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
          }
        } else {
          try{     
              await setDoc(doc(db, "attendance", userData[0].userphone+year+month+day+hours+minutes),{
                  stdname: userData[0].username,
                  stdphone: userData[0].userphone,
                  stdcardno: "",
                  stdid: userData[0].userid,
                  // entertimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분 "+seconds+"초",
                  entertimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분",
                  entertime: now,
                  exittimeprint: '',
                  exittime: '',
                  attenddate: `${year}${month}${day}`,
                  completeyn: 'N',
                  unauthabsence: 'N',
                  unauthregtime: '',
                  unauthdate: '',
                  unauthcomment: '',
                  seatno: userData[0].seatno,
                  groupid: 'sd2',
              });
              setStudentData(userData[0].username+"님 입실완료");
              setTimeData(year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분");
            } catch(error) {
              console.log("에러메세지:", error.message);
          }

          //push알람 로직
          const payload = {
            notification: {
              title: '출석정보',
              body: month+"월 "+day+"일 "+hours+"시 "+minutes+"분 입실완료",
            },
            token: userData[0].token,
          };

          sendPushMessage(payload);

          //부모에게 push 알람 발신
          createPayload(userData[0].username, userData[0].userphone, userData[0].parentphone, "enter")
            .then(payload => {
              sendPushMessage(payload);
            })
            .catch(error => {
              console.error('payload 생성 중 오류 발생:', error);
            })

          //sms 발신 로직
          // const smsPayload = {
          //   phone: userData[0].parentphone,
          //   content: `[시대정신학원]\n${userData[0].username}님 ${month}월 ${day}일 ${hours}시 ${minutes}분 [입실] 완료`,
          // };
          
          // 부모에게 문자발신 대신 푸시메세지로 보내기로 함;
          // sendSms(smsPayload.phone, smsPayload.content);
          setInput([]);
          handleSaveComplete();
        }
      }else{
        Swal.fire({
          icon: "error",
          title: "출결 실패",
          text: "등록되지않은 전화번호 입니다.",
          //showCancelButton: true,
          confirmButtonText: "확인",
          //cancelButtonText: "취소",
        }).then((res) => {
            /* Read more about isConfirmed, isDenied below */
            if (res.isConfirmed) {
                //확인 요청 처리
            }
        });
      }
    }
  }

  async function createPayload(username, userphone, parentphone, enterexit) {
    const now = new Date(); // 현재 날짜 및 시간 가져오기
    // const year = now.getFullYear(); // 연도
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
    const day = String(now.getDate()).padStart(2, '0'); // 일
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    try {
      const docRef = collection(db, "users");
      const q = query(docRef, where("membertype", "==", "P"),
                      where("userphone", "==", parentphone), where("parentphone", "==", userphone),
                      where("groupid", "==", "sd2"), where("token", "!=", ""));
      const docSnap = await getDocs(q);
      if (!docSnap.empty) {
        const userDoc = docSnap.docs[0];
        const token = userDoc.data().token;
        // payload 변수 생성
        let payload
        if(enterexit === "enter"){
          payload = {
            notification: {
                title: '출석정보',
                body: `${username}님 ${month}월 ${day}일 ${hours}시 ${minutes}분 입실완료`,
            },
            token: token,
          };
        }else{
          payload = {
            notification: {
                title: '출석정보',
                body: `${username}님 ${month}월 ${day}일 ${hours}시 ${minutes}분 퇴실완료`,
            },
            token: token,
          };
        }
      return payload;
      } else {
        throw new Error('해당하는 사용자를 찾을 수 없습니다.');
      }
    } catch (error) {
      console.log("에러메세지: ", error);
      throw error;
    }
  }

  const handleClick = (index) => {
    if(index === "del"){
      if (input.length > 0) {
        const updatedInput = input.slice(0, input.length - 1);
        setInput(updatedInput);
      }
    }else{
      if (input.length < 8) {
        setInput([...input, index]);
      }
    }
    
    setSelectedDiv(index);
    // 1초 후에 배경색을 원래 색상으로 변경
    setTimeout(() => {
      setSelectedDiv(null);
    }, 300);
  };

  // 초기화 버튼 클릭 시 입력 배열을 초기화
  const handleResetClick = () => {
    setInput([]);
  };

  return (
    <>
      <div className="content_wrap">
        <div className="sub_title">출석체크(전화번호)</div>
        {isDivVisible && 
          <>
            <div className="overlay"/>
            <div className="save_complete">
              <img src={ImgSuccess} style={{width:"4rem",height:"4rem",marginBottom:"1.875rem"}} alt='완료' />
              <div style={{color:"#000000",fontWeight:500,fontSize:"1.375rem"}}>{studentData}</div>
              <div style={{color:"#BEBEBE",fontWeight:500,fontSize:"0.875rem"}}>{timeData}</div>
            </div>
          </>
        }
        <div className="attchk_mini_title">수강생 전화번호 입력</div>
        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
          <div className="display_num_area">
            <div className="default_unit">0</div>
            <div className="default_unit">1</div>
            <div className="default_unit">0</div>
            <div className="blank" />
            <div className="default_unit" style={{color:"#000000"}}>{input[0]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[1]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[2]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[3]}</div>
            <div className="blank" />
            <div className="default_unit" style={{color:"#000000"}}>{input[4]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[5]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[6]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[7]}</div>
            <img src={BtnDel} style={{width:'1.5rem',height:'1.5rem'}} alt='삭제' onClick={handleResetClick}></img>
          </div>
          <div style={{marginRight:'8.125rem'}}>
            <div style={{display:'flex'}}>
              {[1, 2, 3].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex'}}>
              {[4, 5, 6].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex'}}>
              {[7, 8, 9].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex'}}>
              <div
                key="del"
                className={`button_del ${selectedDiv === "del" ? 'del_selected' : ''}`}
                onClick={() => handleClick("del")}
              >
                ←
              </div>
              <div
                key="0"
                className={`button_unit ${selectedDiv === "0" ? 'selected' : ''}`}
                onClick={() => handleClick("0")}
              >
                0
              </div>
              <div
                className="button_unit"
                style={{backgroundColor:"#11167B", color:"#FFFFFF", fontWeight:500, fontSize:"1.125rem"}}
                onClick={()=>registerWithPhone()}
              >
                출석
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 태블릿 영역 */}
      <div className="modal-overlay_tablet" style={{background:"#FFFFFF"}}>
        <div className="content_wrap_tablet" style={{marginBottom:"200px"}}>
          <div className="sub_title">출석체크(전화번호)</div>
          {isDivVisible && 
            <>
              <div className="overlay"/>
              <div className="save_complete">
                <img src={ImgSuccess} style={{width:"4rem",height:"4rem",marginBottom:"1.875rem"}} alt='완료' />
                <div style={{color:"#000000",fontWeight:500,fontSize:"1.375rem"}}>{studentData}</div>
                <div style={{color:"#BEBEBE",fontWeight:500,fontSize:"0.875rem"}}>{timeData}</div>
              </div>
            </>
          }
          <div className="attchk_mini_title">수강생 전화번호 입력</div>
          <div className="display_num_area">
            <div className="default_unit">0</div>
            <div className="default_unit">1</div>
            <div className="default_unit">0</div>
            <div className="blank" />
            <div className="default_unit" style={{color:"#000000"}}>{input[0]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[1]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[2]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[3]}</div>
            <div className="blank" />
            <div className="default_unit" style={{color:"#000000"}}>{input[4]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[5]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[6]}</div>
            <div className="default_unit" style={{color:"#000000"}}>{input[7]}</div>
            <img src={BtnDel} style={{width:'1.5rem',height:'1.5rem'}} alt='삭제' onClick={handleResetClick}></img>
          </div>
          <div style={{marginTop:"6.25rem"}}>
            <div style={{display:'flex'}}>
              {[1, 2, 3].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex'}}>
              {[4, 5, 6].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex'}}>
              {[7, 8, 9].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex'}}>
              <div
                key="del"
                className={`button_del ${selectedDiv === "del" ? 'del_selected' : ''}`}
                onClick={() => handleClick("del")}
              >
                ←
              </div>
              <div
                key="0"
                className={`button_unit ${selectedDiv === "0" ? 'selected' : ''}`}
                onClick={() => handleClick("0")}
              >
                0
              </div>
              <div
                className="button_unit"
                style={{backgroundColor:"#11167B", color:"#FFFFFF", fontWeight:500, fontSize:"1.125rem"}}
                onClick={()=>registerWithPhone()}
              >
                출석
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 모바일 영역 */}
      <div className="content_wrap_mobile">
        <div className="sub_title attendchk">출석체크</div>
        {isDivVisible && 
          <>
            <div className="overlay"/>
            <div className="save_complete">
              <img src={ImgSuccess} style={{width:"4rem",height:"4rem",marginBottom:"1.875rem"}} alt='완료' />
              <div style={{color:"#000000",fontWeight:500,fontSize:"1.375rem"}}>{studentData}</div>
              <div style={{color:"#BEBEBE",fontWeight:500,fontSize:"0.875rem"}}>{timeData}</div>
            </div>
          </>
        }
        <div style={{padding:"0rem 1.25rem",marginTop:"2.44rem"}}>
          <div className="attchk_mini_title">수강생 전화번호 입력</div>
          <div className="display_num_area">
            <div style={{display:'flex'}}>
              <div className="default_unit">0</div>
              <div className="default_unit">1</div>
              <div className="default_unit">0</div>
              <div className="blank" />
              <div className="default_unit" style={{color:"#000000"}}>{input[0]}</div>
              <div className="default_unit" style={{color:"#000000"}}>{input[1]}</div>
              <div className="default_unit" style={{color:"#000000"}}>{input[2]}</div>
              <div className="default_unit" style={{color:"#000000"}}>{input[3]}</div>
              <div className="blank" />
              <div className="default_unit" style={{color:"#000000"}}>{input[4]}</div>
              <div className="default_unit" style={{color:"#000000"}}>{input[5]}</div>
              <div className="default_unit" style={{color:"#000000"}}>{input[6]}</div>
              <div className="default_unit" style={{color:"#000000"}}>{input[7]}</div>
            </div>
            <img src={BtnDel} style={{width:'1.5rem',height:'1.5rem'}} alt='삭제' onClick={handleResetClick}></img>
          </div>
          <div style={{marginTop:"4.44rem"}}>
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:"0.81rem"}}>
              {[1, 2, 3].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:"0.81rem"}}>
              {[4, 5, 6].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:"0.81rem"}}>
              {[7, 8, 9].map((num) => (
                <div
                  key={num}
                  className={`button_unit ${selectedDiv === num ? 'selected' : ''}`}
                  onClick={() => handleClick(num)}
                >
                  {num}
                </div>
              ))}
            </div>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <div
                key="del"
                className={`button_del ${selectedDiv === "del" ? 'del_selected' : ''}`}
                onClick={() => handleClick("del")}
              >
                ←
              </div>
              <div
                key="0"
                className={`button_unit ${selectedDiv === "0" ? 'selected' : ''}`}
                onClick={() => handleClick("0")}
              >
                0
              </div>
              <div
                className="button_unit"
                style={{backgroundColor:"#11167B", color:"#FFFFFF", fontWeight:500, fontSize:"1.25rem"}}
                onClick={()=>registerWithPhone()}
              >
                출석
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AttendChkPhone;