import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from '../firebase_config.js';
import { where, getDocs, collection, query } from "firebase/firestore";
import Swal from "sweetalert2";


function Login() {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const navigate = useNavigate();
    // const token = props.token;

    const loginProcess = async () => {
        if(loginEmail !== "" && loginPassword !== ""){
            const ref = collection(db, "users");
            const q = query(ref, where("userid", "==", loginEmail), where("auth", "in", [0, 7]));
            const querySnapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

            if(querySnapshot.docs.length !== 0){
                try {
                    await signInWithEmailAndPassword(
                        auth,
                        loginEmail,
                        loginPassword
                    );
                    // TokenCheck(token);
                    navigate("/") //페이지 이동
                } catch(error) {
                Swal.fire({
                    icon: "error",
                    title: "로그인 실패",
                    text: "아이디와 비밀번호를 확인하세요",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
                    console.log("에러메세지:", error.message);
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "로그인 권한없음",
                    text: "해당 사이트 접근권한이 없습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "로그인 실패",
                text: "아이디와 비밀번호를 확인하세요",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            loginProcess();
        }
    }

    const toPersonalIngo = () => {
        navigate("/personalinfo") //페이지 이동
    }

    // async function TokenCheck(props) {
    //     try{
    //         const q = query(collection(db, "users"), where("userId", "==", loginEmail), where("token", "==", props));
    //         const querySnapshot = await getDocs(q);

    //         if(querySnapshot.docs.length === 0){
    //             const docRef = doc(db, "users", loginEmail);
    //             await updateDoc(docRef, {
    //                 token: props
    //             })
    //         }
    //     } catch(error) {
    //         console.log("에러메세지:", error.message);
    //     }
    // }

    return (
        <>
            <div className="content_wrap">
                <div className="sub_title">로그인</div>
                <div className="login_content_area">
                    <div className="content_title">아이디</div>
                    <input 
                        id="_email" 
                        type="email" 
                        placeholder="이메일 주소" 
                        onChange={(e)=>{setLoginEmail(e.target.value);}}>
                    </input>
                    <div className="content_title">비밀번호</div>
                    <input
                        id="_password" 
                        type="password" 
                        placeholder='비밀번호'
                        onChange={(e)=>{setLoginPassword(e.target.value);}}
                        onKeyPress={onKeyPress}
                    >
                    </input>
                    <button onClick={loginProcess}>로그인</button>
                </div>
                <footer>
                    <div className="copyright">
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                        <div className="personal_info" onClick={toPersonalIngo}>개인정보처리방침</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿영역 */}
            <div className="content_wrap_tablet">
                <div className="sub_title">로그인</div>
                <div className="login_content_area">
                    <div className="content_title">아이디</div>
                    <input 
                        id="_email_tablet" 
                        type="email" 
                        placeholder="이메일 주소" 
                        onChange={(e)=>{setLoginEmail(e.target.value);}}>
                    </input>
                    <div className="content_title">비밀번호</div>
                    <input
                        id="_password_tablet" 
                        type="password" 
                        placeholder='비밀번호'
                        onChange={(e)=>{setLoginPassword(e.target.value);}}
                        onKeyPress={onKeyPress}
                    >
                    </input>
                    <button onClick={loginProcess}>로그인</button>
                </div>
                <footer>
                    <div className="copyright">
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                        <div className="personal_info" onClick={toPersonalIngo}>개인정보처리방침</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile">
                <div className="sub_title attendchk">로그인</div>
                <div className="login_content_area">
                    <div className="content_title">아이디</div>
                    <input 
                        id="_email_mobile" 
                        type="email" 
                        placeholder="이메일 주소" 
                        onChange={(e)=>{setLoginEmail(e.target.value);}}>
                    </input>
                    <div className="content_title">비밀번호</div>
                    <input
                        id="_password_mobile" 
                        type="password" 
                        placeholder='비밀번호'
                        onChange={(e)=>{setLoginPassword(e.target.value);}}
                        onKeyPress={onKeyPress}
                    >
                    </input>
                    <button onClick={loginProcess}>로그인</button>
                </div>
            </div>
        </>
    )
}

export default Login;