import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, orderBy } from "firebase/firestore";
import BtnPlus from '../images/buttons/plus.png';
import BtnSearch from '../images/buttons/search.png';
import StudyIcon from '../images/buttons/study_icon_mobile.svg';
import SearchWhite from '../images/buttons/search_white_mobile.svg';

function Notice(props) {
    const navigate = useNavigate();
    const searchRef = useRef(null);
    const searhNameRefMobile = useRef(null);

    const [noticeData, setNoticeData] = useState([]);
    
    const callDb = useCallback(async () => {
        const q = query(collection(db, "bbsdata"),where("reply","==","N"),where("delyn","==","N"),
                    orderBy("regdate",'desc'));
        const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

        const Data = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        setNoticeData(Data);
    }, [])

    useEffect(() => {
        callDb();
    }, [callDb])

    const newModSchedule = () => {
        navigate("/noticewrite",{
            state: {
              contentdata: "NEW"
            },
        })
    }

    //조회 input 처리로직
    const handleSearch = (props) => {
        const searchValue = props;
        
        if(searchValue !== ""){
            const filteredNotices = noticeData.filter(item => 
                item.title.toLowerCase().includes(searchValue.toLowerCase())
            );
            setNoticeData(filteredNotices);
        } else {
            callDb();
        }
    }

    const toNoticeRead = async (id) => {
        navigate("/noticeRead", {
            state: {
              id: id,
            },
        })
    }

    return (
        <>
            <div className="content_wrap">
                <div style={{width:"100%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{width:"40%",color:"#191919",fontWeight:"500",fontSize:"1.38rem"}}>공지사항관리</div>
                    <div style={{display:'flex'}}>
                        <div className="btn_upside" style={{width:"6.38rem",height:"2.5rem"}}>
                            <img src={BtnPlus} className="btn_icon" style={{marginLeft:"0.63rem"}} alt='신규'></img>
                            <div className="btn_text" style={{color:"#0F5EFE"}}
                                onClick={()=>newModSchedule()}
                            >신규생성</div>
                        </div>
                        <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.94rem"}}>
                            <input name="search_input" type="text" placeholder="제목" ref={searchRef}
                                style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                            />
                            <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.81rem",cursor:"pointer"}} alt='검색'
                                onClick={() => {handleSearch(searchRef.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,
                             fontSize:"0.94rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem",marginTop:"4.38rem"}}>
                    <div style={{width:"10%"}}>분류</div>
                    <div style={{width:"10%"}}>댓글</div>
                    <div style={{width:"50%"}}>제목</div>
                    <div style={{width:"10%"}}>글쓴이</div>
                    <div style={{width:"10%"}}>읽음</div>
                    <div style={{width:"10%"}}>날짜</div>
                </div>
                {noticeData
                    .map((doc) => {
                        const regDate = doc.regdate.toDate(); // Firebase Timestamp를 JavaScript Date 객체로 변환

                        // 현재 시간과의 차이 계산
                        const currentTime = new Date();
                        const diffTime = currentTime - regDate;
                        const diffMinutes = Math.floor(diffTime / (1000 * 60)); // 분 단위로 변환
                    
                        let formattedDate = '';
                    
                        if (diffMinutes < 1) {
                            formattedDate = '방금';
                        } else if (diffMinutes < 60) {
                            formattedDate = `${diffMinutes}분 전`;
                        } else if (currentTime.getDate() === regDate.getDate()) {
                            formattedDate = `${regDate.getHours()}:${('0' + regDate.getMinutes()).slice(-2)}`;
                        } else {
                            formattedDate = `${('0' + (regDate.getMonth() + 1)).slice(-2)}-${('0' + regDate.getDate()).slice(-2)}`;
                        }

                        return (
                            <div key={doc.id} onClick={()=>toNoticeRead(doc.id)}
                                style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,
                                    fontSize:"0.94rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem",cursor:'pointer'}}>
                                <div style={{width:"10%"}}>{doc.code}</div>
                                <div style={{width:"10%"}}>{doc.replycount}</div>
                                <div style={{width:"50%"}}>{doc.title}</div>
                                <div style={{width:"10%"}}>{doc.username}</div>
                                <div style={{width:"10%"}}>{doc.count}</div>
                                <div style={{width:"10%"}}>{formattedDate}</div>
                            </div>
                        )
                    }
                )}
                <footer>
                    <div className="copyright" style={{marginTop:"100px",marginBottom:"50px"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"20px"}}>
                    <div style={{width:"40%",color:"#191919",fontWeight:"500",fontSize:"20px"}}>공지사항관리</div>
                    <div style={{display:'flex'}}>
                        <div className="btn_upside" style={{width:"40px",height:"40px",marginRight:"10px"}}>
                            <img src={BtnPlus} className="btn_icon" alt='신규'
                                onClick={()=>newModSchedule()}>
                            </img>
                        </div>
                        <div style={{display:'flex',justifyContent:"space-between",width:"140px",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"15px"}}>
                            <input name="search_input" type="text" placeholder="제목" ref={searchRef}
                                style={{width:"100px",fontWeight:400,fontSize:"12px",color:"#A6A7AB"}}     
                            />
                            <img src={BtnSearch} className="btn_icon" style={{marginLeft:"13px",cursor:"pointer"}} alt='검색'
                                onClick={() => {handleSearch(searchRef.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{width:"95%",height:"3rem",marginTop:"3.125rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"1rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                    <div style={{width:"10%"}}>분류</div>
                    <div style={{width:"10%"}}>댓글</div>
                    <div style={{width:"50%"}}>제목</div>
                    <div style={{width:"10%"}}>글쓴이</div>
                    <div style={{width:"10%"}}>읽음</div>
                    <div style={{width:"10%"}}>날짜</div>
                </div>
                {noticeData
                    .map((doc) => {
                        const regDate = doc.regdate.toDate(); // Firebase Timestamp를 JavaScript Date 객체로 변환

                        // 현재 시간과의 차이 계산
                        const currentTime = new Date();
                        const diffTime = currentTime - regDate;
                        const diffMinutes = Math.floor(diffTime / (1000 * 60)); // 분 단위로 변환
                    
                        let formattedDate = '';
                    
                        if (diffMinutes < 1) {
                            formattedDate = '방금';
                        } else if (diffMinutes < 60) {
                            formattedDate = `${diffMinutes}분 전`;
                        } else if (currentTime.getDate() === regDate.getDate()) {
                            formattedDate = `${regDate.getHours()}:${('0' + regDate.getMinutes()).slice(-2)}`;
                        } else {
                            formattedDate = `${('0' + (regDate.getMonth() + 1)).slice(-2)}-${('0' + regDate.getDate()).slice(-2)}`;
                        }

                        return (
                            <div key={doc.id} onClick={()=>toNoticeRead(doc.id)}
                                style={{width:"95%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,
                                    fontSize:"0.94rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem",cursor:'pointer'}}>
                                <div style={{width:"10%"}}>{doc.code}</div>
                                <div style={{width:"10%"}}>{doc.replycount}</div>
                                <div style={{width:"50%"}}>{doc.title}</div>
                                <div style={{width:"10%"}}>{doc.username}</div>
                                <div style={{width:"10%"}}>{doc.count}</div>
                                <div style={{width:"10%"}}>{formattedDate}</div>
                            </div>
                        )
                    }
                )}
                <footer>
                    <div className="copyright" style={{marginTop:"100px",marginBottom:"50px"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title studentmng">
                    <div style={{display:'flex',alignItems:'center'}}>
                        <img src={StudyIcon} alt='공지사항관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                        <div>공지사항관리</div>
                    </div>
                    <button className="add_student">
                        <img src={BtnPlus} alt='신규'
                            onClick={()=>newModSchedule()}>
                        </img>
                    </button>
                </div>
                <div style={{marginTop:"1.5rem",display:'flex',justifyContent:'flex-end'}}>
                    <div className="search_name_area">
                        <input name="search_input" type="text" placeholder="제목" ref={searhNameRefMobile}/>
                        <div className="button_area">
                            <img src={SearchWhite} style={{width:'0.9375rem',height:'0.99613rem'}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefMobile.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="notice_code">분류</div>
                        <div className="notice_replycnt">댓글</div>
                        <div className="notice_title">제목</div>
                        <div className="notice_user">작성자</div>
                        <div className="notice_count">읽음</div>
                        <div className="notice_registdate">날짜</div>
                    </div>
                    {noticeData
                        .map((doc) => {
                            const regDate = doc.regdate.toDate(); // Firebase Timestamp를 JavaScript Date 객체로 변환

                            // 현재 시간과의 차이 계산
                            const currentTime = new Date();
                            const diffTime = currentTime - regDate;
                            const diffMinutes = Math.floor(diffTime / (1000 * 60)); // 분 단위로 변환
                        
                            let formattedDate = '';
                        
                            if (diffMinutes < 1) {
                                formattedDate = '방금';
                            } else if (diffMinutes < 60) {
                                formattedDate = `${diffMinutes}분 전`;
                            } else if (currentTime.getDate() === regDate.getDate()) {
                                formattedDate = `${regDate.getHours()}:${('0' + regDate.getMinutes()).slice(-2)}`;
                            } else {
                                formattedDate = `${('0' + (regDate.getMonth() + 1)).slice(-2)}-${('0' + regDate.getDate()).slice(-2)}`;
                            }

                            return (
                                <div className="body_area_mobile" key={doc.id} onClick={()=>toNoticeRead(doc.id)}>
                                    <div className="notice_code">{doc.code}</div>
                                    <div className="notice_replycnt">{doc.replycount}</div>
                                    <div className="notice_title">{doc.title}</div>
                                    <div className="notice_user">{doc.username}</div>
                                    <div className="notice_count">{doc.count}</div>
                                    <div className="notice_registdate">{formattedDate}</div>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
        </>
    );
}

export default Notice;