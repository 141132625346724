import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, where, getDoc, getDocs, doc, setDoc, Timestamp, orderBy, updateDoc, increment } from "firebase/firestore";
import Swal from "sweetalert2";
import StudyIcon from '../images/buttons/study_icon_mobile.svg';
import Download from '../images/buttons/download_mobile.svg';
import DeleteReply from '../images/buttons/delete_mobile.svg';

function NoticeRead(props) {
    const navigate = useNavigate();
    const userInfo = props.userAuth;
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const bbsdataid = state.id;

    const [isLoading, setIsLoading] = useState(true);
    const [noticeData, setNoticeData] = useState();
    const [replyData, setReplyData] = useState([]);
    const [userData, setUserData] = useState();
    const [reply, setReply] = useState('');
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const callDb = useCallback(async () => {
        setIsLoading(true);
        // 접속자 정보
        const userQuery = query(collection(db, "users"), where("userid", "==", userInfo.email));
        const userSnapshot = await getDocs(userQuery);
        const userData = userSnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        });  
        setUserData(userData[0]);

        const docRef = doc(db, "bbsdata", bbsdataid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            const data = docSnap.data();
            setNoticeData({
                id: docSnap.id,  // 문서 ID 추가
                ...data  // 기존 문서 데이터 전개
            });
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }

        const q = query(collection(db, "bbsdata"), where("code", "==", docSnap.data().code), where("delyn", "==", "N"),
                        where("reply", "==", "Y"), where("replydest", "==", bbsdataid), orderBy("replyseq"));
        const snapshot = await getDocs(q);
  
        const replyData = snapshot.docs.map(doc => {
          const timestamp = doc.data().regdate.toDate();
          const formattedDate = `${timestamp.getFullYear()}-${(timestamp.getMonth() + 1).toString().padStart(2, '0')}-${timestamp.getDate().toString().padStart(2, '0')} ${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}:${timestamp.getSeconds().toString().padStart(2, '0')}`;
  
          return {
            ...doc.data(),
            id: doc.id,
            regdate: formattedDate
          };
        });
        setReplyData(replyData);

        setIsLoading(false);
    }, [bbsdataid, userInfo.email])

    useEffect(() => {
        callDb();
    }, [callDb, refreshTrigger])

    // 날짜 포맷팅 함수
    const formatDate = (timestamp) => {
        if (!(timestamp instanceof Timestamp)) {
            return '';  // 또는 다른 기본값
        }
        
        const date = timestamp.toDate();
        return new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }).format(date).replace(/\. /g, '-').replace('.', '');
    };

    const handleReplyChange = (event) => {
        setReply(event.target.value);
    };

    const handleUpload = async () => {
        if( reply !== ""){
            const now = new Date();

            // "code" 필드가 noticeCode인 데이터의 개수 가져오기
            const noticeQuery = query(collection(db, "bbsdata"), where("code", "==", noticeData.code));
            const noticeSnapshot = await getDocs(noticeQuery);
            const noticeCount = noticeSnapshot.size+1;

            // "replydest" 필드가 게시글 아이디인 데이터의 개수 가져오기
            const replyQuery = query(collection(db, "bbsdata"), where("code", "==", noticeData.code),
                                where("replydest", "==", bbsdataid));
            const replySnapshot = await getDocs(replyQuery);
            const replyCount = replySnapshot.size+1;

            const dataId = noticeData.code+noticeCount
            const docRef = doc(db, "bbsdata", dataId);
        
            // 데이터베이스에 저장할 객체 생성
            const bbsData = {
                code: noticeData.code,
                seq: noticeCount,
                userid: userData.userid,
                username: userData.username,
                title: "",
                content: reply,
                regdate: now,
                reply: "Y",
                replycount: 0,
                replyseq: replyCount,
                replydest: bbsdataid,
                count: 0,
                delyn: "N",
                istop: "",
                attachedfile1: "",
                attachedfile2: "",
                attachedfile3: "",
                attachedfile4: "",
                attachedfile5: "",
                attachedfilename1: "",
                attachedfilename2: "",
                attachedfilename3: "",
                attachedfilename4: "",
                attachedfilename5: "",
            };

            // 데이터베이스에 저장
            try { 
                await setDoc(docRef, bbsData);

                const ref = doc(db, "bbsdata", bbsdataid);
                await updateDoc(ref, {
                    replycount: increment(1)
                })
                // 폼 초기화
                // setTitle('');
                setReply('');
                // setSelectedFiles([]);
                // setActiveCategory("공지사항");
                // 리렌더링 트리거
                setRefreshTrigger(prev => prev + 1);
            } catch (error) {
                console.error("Error adding document: ", error);
                showAlert("error", "업로드 실패", "다시 시도해주세요.");
            }
        }else {
            showAlert("error", "등록된 리플 없음", "등록된 리플이 없습니다.");
        }
    };

    const delReply = (props) => {
        Swal.fire({
          icon: "question",
          title: "댓글 삭제",
          text: "댓글 삭제하시겠습니까?",
          showCancelButton: true,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        }).then(async (res) => {
          /* Read more about isConfirmed, isDenied below */
          if (res.isConfirmed) {
            //확인 요청 처리
            const docRef = doc(db, "bbsdata", props);
            await updateDoc(docRef, {
              delyn: "Y"
            })

            const ref = doc(db, "bbsdata", bbsdataid);
            await updateDoc(ref, {
                replycount: increment(-1)
            })
    
            setRefreshTrigger(prev => prev + 1);
          }
        });
    }

    const delContent = (props) => {
        Swal.fire({
          icon: "question",
          title: "삭제",
          text: "삭제하시겠습니까?",
          showCancelButton: true,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        }).then(async (res) => {
          /* Read more about isConfirmed, isDenied below */
          if (res.isConfirmed) {
            //확인 요청 처리
            const docRef = doc(db, "bbsdata", props);
            await updateDoc(docRef, {
              delyn: "Y"
            })
            navigate("/notice");
          }
        });
    }

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <>
            {isLoading ? (
                <div />
            ) : (
                <>
                    <div className="content_wrap" style={{alignItems:'flex-start'}}>
                        <div style={{width:"100%",marginTop:"1.25rem"}}>
                            <div style={{color:"#191919",fontWeight:"500",fontSize:"1.38rem"}}>공지사항</div>
                        </div>
                        <div style={{marginTop:"2.38rem",padding:"0.3rem 0.5rem",background:'#F0F0F0',width:"100%"}}>
                            <div>{noticeData.username}</div>
                            <div style={{display:'flex'}}>
                                <div>{noticeData.code}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>등록일: {formatDate(noticeData.regdate)}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>글번호: {noticeData.seq}</div>
                            </div>
                            <div style={{display:'inline-flex',padding:"0.1rem 0.2rem",background:"#FFF",border:"1px grey solid"}}>{noticeData.count}명이 읽었어요</div>
                        </div>
                        <div style={{padding:"1rem"}}>
                            <div style={{fontSize:"1.2rem",fontWeight:600,marginBottom:"2rem"}}>{noticeData.title}</div>
                            <div>{noticeData.content}</div>
                            <div style={{width:"50rem",marginTop:"2rem"}}>
                                {[1, 2, 3, 4, 5].map(index => (
                                    noticeData[`attachedfilename${index}`] && (
                                        <img 
                                            key={index}
                                            src={noticeData[`attachedfile${index}`]} 
                                            alt={noticeData[`attachedfilename${index}`]}
                                            style={{border:"0.5px #191919 solid"}}
                                        />
                                    )
                                ))}
                            </div>
                        </div>
                        <div style={{marginTop:"2.38rem"}}>첨부파일</div>
                        <div style={{padding:"0.3rem 0.5rem",background:'#F0F0F0', width:"100%"}}>
                            {[1, 2, 3, 4, 5].map(index => (
                                noticeData[`attachedfilename${index}`] && (
                                <div key={index} style={{display:'flex'}}>
                                    <div style={{marginRight:"0.3rem"}}>{noticeData[`attachedfilename${index}`]}</div>
                                    <button onClick={() => window.open(noticeData[`attachedfile${index}`], '_blank')}>다운로드</button>
                                </div>
                                )
                            ))}
                        </div>
                        <div style={{margin:"1.5rem 0rem",display:'flex',width:"100%",justifyContent:'space-between'}}>
                            <div>댓글수 {replyData.length}</div>
                            <div style={{display:'flex'}}>
                                <button onClick={()=>navigate("/noticewrite",{
                                    state: {
                                        contentdata: noticeData,
                                    },
                                })}>수정하기</button>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <button onClick={()=>delContent(bbsdataid)}>삭제하기</button>
                            </div>
                        </div>
                        {replyData.map((option, index) => {
                            return (
                                <div key={index} style={{width:"100%",borderTop:"1px #F0F0F0 solid",padding:"0.3rem 0rem"}}>
                                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <div style={{fontSize:"0.875rem",fontWeight:600,padding:"0.1875rem 0rem",
                                                        background: option.username === "관리자" && '#FFF2E3',}}
                                            >{option.username}</div>
                                            <div style={{color:'#CECECE',marginLeft:"0.75rem",fontSize:"0.75rem",fontWeight:500}}>{option.regdate}</div>
                                        </div>
                                        <button onClick={()=>delReply(option.id)}>삭제</button>
                                    </div>
                                    <div style={{marginTop:"0.9375rem",fontSize:"0.875rem",color:'#707070',fontWeight:500,whiteSpace:'pre-wrap'}}>{option.content}</div>
                                </div>
                            )
                        })}
                        <div style={{width:"100%",borderTop:"1px #F0F0F0 solid"}} />
                        <div style={{width:"100%",display:'flex',justifyContent:'space-between',marginTop:"1.5rem"}}>
                            <div>{userData.username}님의 댓글을 남겨주세요</div>
                            <div style={{display:'flex'}}>
                                <div>{noticeData.code}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>{noticeData.title}</div>
                            </div>
                        </div>
                        <textarea style={{border:"1px grey solid",padding:'0.5rem 1rem',width:"100%",
                            height:"10rem",resize:'none'}}
                            placeholder="불량댓글 작성시 이용정지 등의 제재를 받습니다."
                            value={reply}
                            onChange={handleReplyChange}
                        />
                        <div style={{width:"100%",display:'flex',justifyContent:'center',margin:"3rem 0rem"}}>
                            <button onClick={handleUpload}
                                style={{padding:"0.5rem 1rem",background:"#000",color:"#FFF",cursor:'pointer',marginRight:"1rem"}}
                            >댓글전송</button>
                            <button onClick={()=>navigate(-1)}
                                style={{padding:"0.5rem 1rem",background:"#FFF",color:"#000",cursor:'pointer'}}
                            >뒤로</button>
                        </div>
                    </div>
                    {/* 태블릿 영역 */}
                    <div className="content_wrap_tablet">
                        <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"20px"}}>
                            <div style={{width:"40%",color:"#191919",fontWeight:"500",fontSize:"20px"}}>공지사항</div>
                        </div>
                        <div style={{marginTop:"2.38rem",padding:"0.3rem 0.5rem",background:'#F0F0F0',width:"95%"}}>
                            <div>{noticeData.username}</div>
                            <div style={{display:'flex'}}>
                                <div>{noticeData.code}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>등록일: {formatDate(noticeData.regdate)}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>글번호: {noticeData.seq}</div>
                            </div>
                            <div style={{display:'inline-flex',padding:"0.1rem 0.2rem",background:"#FFF",border:"1px grey solid"}}>{noticeData.count}명이 읽었어요</div>
                        </div>
                        <div style={{padding:"1rem",width:'95%'}}>
                            <div style={{fontSize:"1.2rem",fontWeight:600,marginBottom:"2rem"}}>{noticeData.title}</div>
                            <div>{noticeData.content}</div>
                            <div style={{width:"50rem",marginTop:"2rem"}}>
                                {[1, 2, 3, 4, 5].map(index => (
                                    noticeData[`attachedfilename${index}`] && (
                                        <img 
                                            key={index}
                                            src={noticeData[`attachedfile${index}`]} 
                                            alt={noticeData[`attachedfilename${index}`]}
                                            style={{border:"0.5px #191919 solid"}}
                                        />
                                    )
                                ))}
                            </div>
                        </div>
                        <div style={{marginTop:"2.38rem",width:'95%'}}>첨부파일</div>
                        <div style={{padding:"0.3rem 0.5rem",background:'#F0F0F0',width:"95%"}}>
                            {[1, 2, 3, 4, 5].map(index => (
                                noticeData[`attachedfilename${index}`] && (
                                <div key={index} style={{display:'flex'}}>
                                    <div style={{marginRight:"0.3rem"}}>{noticeData[`attachedfilename${index}`]}</div>
                                    <button onClick={() => window.open(noticeData[`attachedfile${index}`], '_blank')}>다운로드</button>
                                </div>
                                )
                            ))}
                        </div>
                        <div style={{margin:"1.5rem 0rem",display:'flex',width:"95%",justifyContent:'space-between'}}>
                            <div>댓글수 {replyData.length}</div>
                            <div style={{display:'flex'}}>
                                <button onClick={()=>navigate("/noticewrite",{
                                    state: {
                                        contentdata: noticeData,
                                    },
                                })}>수정하기</button>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <button onClick={()=>delContent(bbsdataid)}>삭제하기</button>
                            </div>
                        </div>
                        {replyData.map((option, index) => {
                            return (
                                <div key={index} style={{width:"95%",borderTop:"1px #F0F0F0 solid",padding:"0.3rem 0rem"}}>
                                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <div style={{fontSize:"0.875rem",fontWeight:600,padding:"0.1875rem 0rem",
                                                        background: option.username === "관리자" && '#FFF2E3',}}
                                            >{option.username}</div>
                                            <div style={{color:'#CECECE',marginLeft:"0.75rem",fontSize:"0.75rem",fontWeight:500}}>{option.regdate}</div>
                                        </div>
                                        <button onClick={()=>delReply(option.id)}>삭제</button>
                                    </div>
                                    <div style={{marginTop:"0.9375rem",fontSize:"0.875rem",color:'#707070',fontWeight:500,whiteSpace:'pre-wrap'}}>{option.content}</div>
                                </div>
                            )
                        })}
                        <div style={{width:"95%",borderTop:"1px #F0F0F0 solid"}} />
                        <div style={{width:"95%",display:'flex',justifyContent:'space-between',marginTop:"1.5rem"}}>
                            <div>{userData.username}님의 댓글을 남겨주세요</div>
                            <div style={{display:'flex'}}>
                                <div>{noticeData.code}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>{noticeData.title}</div>
                            </div>
                        </div>
                        <textarea style={{border:"1px grey solid",padding:'0.5rem 1rem',width:"95%",
                            height:"10rem",resize:'none'}}
                            placeholder="불량댓글 작성시 이용정지 등의 제재를 받습니다."
                            value={reply}
                            onChange={handleReplyChange}
                        />
                        <div style={{width:"95%",display:'flex',justifyContent:'center',margin:"3rem 0rem"}}>
                            <button onClick={handleUpload}
                                style={{padding:"0.5rem 1rem",background:"#000",color:"#FFF",cursor:'pointer',marginRight:"1rem"}}
                            >댓글전송</button>
                            <button onClick={()=>navigate(-1)}
                                style={{padding:"0.5rem 1rem",background:"#FFF",color:"#000",cursor:'pointer'}}
                            >뒤로</button>
                        </div>
                        <footer>
                            <div className="copyright" style={{marginTop:"100px",marginBottom:"50px"}}>
                                <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                            </div>
                        </footer>
                    </div>
                    {/* 모바일 영역 */}
                    <div className="content_wrap_mobile padding_horizonal">
                        <div className="sub_title">
                            <div style={{display:'flex',alignItems:'center'}}>
                                <img src={StudyIcon} alt='공지사항' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                                <div>공지사항</div>
                            </div>
                        </div>
                        <div style={{marginTop:"6rem"}}>
                            <div className="notice_subtitle">{noticeData.title}</div>
                            <div className="notice_read_info">
                                <div>{noticeData.code}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>{noticeData.username}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>{formatDate(noticeData.regdate)}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>글번호 {noticeData.seq}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>조회 {noticeData.count}</div>
                            </div>
                        </div>
                        <div className="notice_content notice_read">
                            <div>{noticeData.content}</div>
                            <div style={{width:"100%",marginTop:"1rem"}}>
                                {[1, 2, 3, 4, 5].map(index => (
                                    noticeData[`attachedfilename${index}`] && (
                                        <img 
                                            key={index}
                                            src={noticeData[`attachedfile${index}`]} 
                                            alt={noticeData[`attachedfilename${index}`]}
                                        />
                                    )
                                ))}
                            </div>
                        </div>
                        <div className="notice_read_subtitle">첨부파일</div>
                        {[1, 2, 3, 4, 5].map(index => (
                            noticeData[`attachedfilename${index}`] && (
                            <div className="notice_filelist" key={index}
                                onClick={() => window.open(noticeData[`attachedfile${index}`], '_blank')}
                            >
                                <div>{noticeData[`attachedfilename${index}`]}</div>
                                <img src={Download} alt='다운로드' />
                            </div>
                            )
                        ))}
                        <div style={{display:'flex'}}>
                            <div className="notice_read_subtitle">댓글</div>
                            <div className="notice_read_subtitle replycount">{replyData.length}</div>
                        </div>
                        {replyData.map((option, index) => {
                            return (
                                <div key={index} className="notice_reply_area" style={{background: option.username === "운영자" && '#DBE9FE'}}>
                                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <div className="text">{option.replyseq}</div>
                                            <div className="text username">{option.username}</div>
                                            <div className="regdate">{option.regdate}</div>
                                        </div>
                                        <img src={DeleteReply} alt='리플삭제' onClick={()=>delReply(option.id)} />
                                    </div>
                                    <div className="reply">{option.content}</div>
                                </div>
                            )
                        })}
                        <div className="notice_content write_reply">
                            <div className="notice_read_subtitle">댓글작성</div>
                            <textarea 
                                className="reply"
                                placeholder="불량댓글 작성시 이용정지 등의 제재를 받습니다."
                                value={reply}
                                onChange={handleReplyChange}
                            />
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end',marginTop:"0.63rem"}}>
                            <button className="notice_save reply_save" onClick={handleUpload}>등록</button>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',margin:"5rem 0rem"}}>
                            <button className="notice_save" onClick={()=>navigate("/noticewrite",{
                                    state: {
                                        contentdata: noticeData,
                                    },
                                })}
                            >수정</button>
                            <div style={{width:"0.37rem"}} />
                            <button className="notice_save del" onClick={()=>delContent(bbsdataid)}>삭제</button>
                            <div style={{width:"0.37rem"}} />
                            <button className="notice_save back" onClick={()=>navigate(-1)}>뒤로</button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default NoticeRead;