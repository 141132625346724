import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, updateDoc, doc, Timestamp } from "firebase/firestore";
import Swal from "sweetalert2";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BtnSearch from '../images/buttons/search.png';
import BtnDown from '../images/buttons/arrow_down.png';
import BtnArrowDown from '../images/buttons/arrow_down_grey_16.png'
import AbsenteeIcon from '../images/buttons/absentee_icon_mobile.svg';
import ArrowUpDown from '../images/buttons/arrow_updown_mobile.svg';
import SearchWhite from '../images/buttons/search_white_mobile.svg';


function UnAuthAbsenteeMng() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const studentData = state.studentdata;

    const now = new Date();
    const [year, setYear] = useState(now.getFullYear());
    const [month, setMonth] = useState(now.getMonth()+1);
    const [day, setDay] = useState(now.getDate());

    const [attendData, setAttendData] = useState([]);
    const [attendDataRaw, setAttendDataRaw] = useState([]);
    const [sortCriteria, setSortCriteria] = useState('seatno'); // 초기 정렬 기준은 좌석번호
    const [showCalendar, setShowCalendar] = useState(false);  //달력 보이기 기능
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [displayDate, setDisplayDate] = useState(year+"년 "+month+"월 "+day+"일");
    const calendarRef = useRef(null);
    const searhNameRef = useRef(null);
    const searhNameRefTablet = useRef(null);
    const searhNameRefMobile = useRef(null);

    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);

    const callDb = useCallback(async () => {
        //출석 데이터
        const q = query(collection(db, "attendance"), where("unauthabsence", "==", "Y"),
                            where("unauthdate", "==", String(year)+"-"+String(month)+"-"+String(day)),
                            where("groupid", "==", "sd2"))
        
        const snapshot = await getDocs(q); 
        const queryData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        // stdData의 seatno 필드 데이터를 schData 추가
        const combinedData = queryData.map((absentee) => {
            const matchingStudent = studentData.find((student) => student.userphone === absentee.stdphone);
            return {
            ...absentee,
            seatno: matchingStudent ? matchingStudent.seatno : "",
            };
        });
        setAttendData(combinedData);
        setAttendDataRaw(combinedData);
    }, [day, month, year, studentData])

    useEffect(() => {
        callDb();
    },[callDb, updateState, year, month, day])

    const processUnauthAbsence = async (props) => {
        try{
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth()+1;
            const date = now.getDate();

            const docRef = doc(db, "attendance", props)
            await updateDoc(docRef, {
                unauthabsence: "N",
                unauthregtime: now,
                unauthdate: year.toString()+"-"+month.toString()+"-"+date.toString(),
                unauthcomment: "",
            });
      
            Swal.fire({
                icon: "success",
                title: "처리 완료",
                text: "무단부재자 등록해제가 완료되었습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                }
            });
        }catch(error){
            console.log(error.message);
        }
    }

    const toAbsenteeMng = () => {
        navigate("/absenteemng")
    }

    function ViewCalendar() {
        const currentDate = new Date(); // 현재 날짜로 설정
      
        return (
            <>
                {showCalendar && (
                    <div className="sliding_calendar" 
                        style={{ top: calendarPosition.top, left: calendarPosition.left }}
                        ref={calendarRef}
                    >
                        <Calendar
                            onChange={(e)=>{
                                const timestampDate = Timestamp.fromDate(e);
                                const scheDate = new Date(timestampDate.toMillis());
                                const Year = scheDate.getFullYear();
                                const Month = scheDate.getMonth()+1;
                                const Day = scheDate.getDate();
                                setDisplayDate(Year.toString()+"년 "+Month.toString()+"월 "+Day.toString()+"일")
                                setYear(Year);
                                setMonth(Month);
                                setDay(Day);
                                setShowCalendar(!showCalendar);
                            }}
                            defaultActiveStartDate={currentDate}
                        />
                    </div>
                )}
            </>
        );
    }

    //이름조회 input 처리로직
    const handleSearch = (props) => {
        // const nameValue = searhNameRef.current.value;
        const nameValue = props;
        setAttendData(attendDataRaw);
        
        if(nameValue !== ""){
            const filteredCustomer = attendDataRaw.filter(item => item.stdname.includes(nameValue));
            setAttendData(filteredCustomer);
        }else{
            callDb();
        }
    }

    const commentReg = (popupName, id, val) => {
        navigate("/popup", {
            state: {
                popupName: popupName,
                colId: id,
                val: val,
            },
        })
    }

    const handleSortClick = (criteria) => {
        setSortCriteria(criteria);
    };

    return (
        <>
            <div className="content_wrap">
                <div style={{width:"100%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toAbsenteeMng}
                        >부재자관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent">무단부재자관리</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"9.25rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRef}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRef.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"100%",marginTop:"4.375rem",marginBottom:"1.25rem"}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"16.13rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6"}}
                        onClick={(e)=>{ 
                            const { top, left, height } = e.target.getBoundingClientRect();
                            const newTop = top + height; // 원하는 위치 조절
                            const newLeft = left; // 원하는 위치 조절
                            setCalendarPosition({ top: newTop, left: newLeft });
                            setShowCalendar(!showCalendar);
                        }}>
                        <div style={{color:"#0F5EFE",fontWeight:500,fontSize:"1.5rem",marginRight:"0.625rem"}}>{displayDate}</div>
                        <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                    </div> 
                </div>
                <div style={{width:"100%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex'}}>
                    <div className="btn_midside" style={{width:"6.375rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('stdname')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <ViewCalendar />
                <div style={{width:"100%",height:"2.5rem",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{width:"6.25rem"}}>이름</div>
                    <div style={{width:"6.25rem"}}>좌석번호</div>
                    <div style={{width:"9.375rem"}}>전화번호</div>
                    <div style={{width:"9.375rem"}}>학부모 전화번호</div>
                    <div style={{width:"12.5rem"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"12.5rem"}}>등록일시</div>
                    <div style={{width:"25rem",textAlign:'center'}}>비고</div>
                    <div style={{width:"6.25rem",textAlign:'center'}}>삭제</div>
                </div>
                {attendData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'stdname') {
                            // 이름을 문자열로 비교
                            return a.stdname.localeCompare(b.stdname);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        const formatPhoneNumber = (phoneNumber) => {
                            const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                            const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                        
                            if (match) {
                            return match[1] + '-' + match[2] + '-' + match[3];
                            }
                        
                            return phoneNumber;
                        };

                        const student = studentData.filter(item => item.userphone === doc.stdphone);
                        const parentPhone = student[0].parentphone;
                        const school = student[0].school;
                        const grade = student[0].grade;
                        const gender = student[0].gender;

                        let genderTxt;
                        
                        if(gender === "M"){
                            genderTxt = "남";
                        }else if(gender === "F"){
                            genderTxt = "여";
                        }else{
                            genderTxt = "";
                        }
                        const userInfo = school+" / "+grade+" / "+genderTxt;


                        const itemDate = doc.unauthregtime.toDate();
                        const itemYear = itemDate.getFullYear();
                        const itemMonth = itemDate.getMonth() + 1;
                        const itemDay = itemDate.getDate();
                        const itemHours = itemDate.getHours();
                        const itemMinutes = itemDate.getMinutes();

                        return (
                            <div style={{width:"100%",height:"2.5rem",display:'flex',
                                            color:"#191919",fontWeight:500,fontSize:"0.875rem",
                                            lineHeight:"2.5rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid",
                                        }} key={doc.id}>
                                <div style={{width:"6.25rem"}}>{doc.stdname}</div>
                                <div style={{width:"6.25rem"}}>{doc.seatno}</div>
                                <div style={{width:"9.375rem"}}>{formatPhoneNumber(doc.stdphone)}</div>
                                <div style={{width:"9.375rem"}}>{formatPhoneNumber(parentPhone)}</div>
                                <div style={{width:"12.5rem"}}>{userInfo}</div>
                                <div style={{width:"12.5rem"}}>{itemYear}년 {itemMonth}월 {itemDay}일 {itemHours}시 {itemMinutes}분</div>
                                {doc.unauthcomment !== "" ? (
                                    <div style={{width:"25rem",cursor:"pointer",textAlign:'center'}}
                                        onClick={()=>commentReg("comment", doc.id, doc.unauthcomment)}
                                    >{doc.unauthcomment}</div>
                                ) : (
                                    <div style={{width:"25rem",textAlign:'center'}}>
                                        <button className="btn_moddel" style={{color:"#8D8D8D"}}  
                                                onClick={()=>commentReg("comment", doc.id, "")}
                                        >작성</button>
                                    </div>
                                )}
                                <div style={{width:"6.25rem",textAlign:'center'}}>
                                    <button className="btn_moddel" style={{color:"#FF6F6F"}}  onClick={()=>processUnauthAbsence(doc.id)}>삭제</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toAbsenteeMng}
                        >부재자관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent">무단부재자관리</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefTablet}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRefTablet.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"4.375rem",marginBottom:"1.25rem"}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"16.13rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6"}}
                        onClick={(e)=>{ 
                            const { top, left, height } = e.target.getBoundingClientRect();
                            const newTop = top + height; // 원하는 위치 조절
                            const newLeft = left; // 원하는 위치 조절
                            setCalendarPosition({ top: newTop, left: newLeft });
                            setShowCalendar(!showCalendar);
                        }}>
                        <div style={{color:"#0F5EFE",fontWeight:500,fontSize:"1.5rem",marginRight:"0.625rem"}}>{displayDate}</div>
                        <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                    </div> 
                </div>
                <ViewCalendar />
                <div style={{width:"95%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex'}}>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('stdname')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"95%",height:"3rem",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"1rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"3rem"}}>
                    {/* <div style={{width:"120px"}}>이름</div>
                    <div style={{width:"200px"}}>전화번호</div>
                    <div style={{width:"200px"}}>학부모 전화번호</div>
                    <div style={{width:"200px"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"200px"}}>등록일시</div>
                    <div style={{width:"100px",textAlign:'center'}}>삭제</div> */}
                    <div style={{width:"10%"}}>이름</div>
                    <div style={{width:"8%"}}>좌석번호</div>
                    <div style={{width:"15%"}}>전화번호</div>
                    <div style={{width:"15%"}}>학부모 전화번호</div>
                    <div style={{width:"20%"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"22%"}}>등록일시</div>
                    <div style={{width:"10%",textAlign:'center'}}>삭제</div>
                </div>
                {attendData.map((doc) => {
                    const formatPhoneNumber = (phoneNumber) => {
                        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                        const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                    
                        if (match) {
                        return match[1] + '-' + match[2] + '-' + match[3];
                        }
                    
                        return phoneNumber;
                    };

                    const student = studentData.filter(item => item.userphone === doc.stdphone);
                    const parentPhone = student[0].parentphone
                    const school = student[0].school;
                    const grade = student[0].grade;
                    const gender = student[0].gender;

                    let genderTxt;
                    
                    if(gender === "M"){
                        genderTxt = "남";
                    }else if(gender === "F"){
                        genderTxt = "여";
                    }else{
                        genderTxt = "";
                    }
                    const userInfo = school+" / "+grade+" / "+genderTxt;


                    const itemDate = doc.unauthregtime.toDate();
                    const itemYear = itemDate.getFullYear();
                    const itemMonth = itemDate.getMonth() + 1;
                    const itemDay = itemDate.getDate();
                    const itemHours = itemDate.getHours();
                    const itemMinutes = itemDate.getMinutes();

                    return (
                        <div style={{width:"95%",height:"3rem",display:'flex',
                                        color:"#191919",fontWeight:400,fontSize:"0.9375rem",
                                        lineHeight:"3rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid",
                                    }} key={doc.id}>
                            {/* <div style={{width:"120px"}}>{doc.stdname}</div>
                            <div style={{width:"200px"}}>{formatPhoneNumber(doc.stdphone)}</div>
                            <div style={{width:"200px"}}>{formatPhoneNumber(parentPhone)}</div>
                            <div style={{width:"200px"}}>{userInfo}</div>
                            <div style={{width:"200px"}}>{itemYear}년 {itemMonth}월 {itemDay}일 {itemHours}시 {itemMinutes}분</div>
                            <div style={{width:"100px",textAlign:'center'}}> */}
                            <div style={{width:"10%"}}>{doc.stdname}</div>
                            <div style={{width:"8%"}}>{doc.seatno}</div>
                            <div style={{width:"15%"}}>{formatPhoneNumber(doc.stdphone)}</div>
                            <div style={{width:"15%"}}>{formatPhoneNumber(parentPhone)}</div>
                            <div style={{width:"20%"}}>{userInfo}</div>
                            <div style={{width:"22%"}}>{itemYear}년 {itemMonth}월 {itemDay}일 {itemHours}시 {itemMinutes}분</div>
                            <div style={{width:"10%",textAlign:'center'}}>
                                <button className="btn_moddel" style={{color:"#FF6F6F"}}  onClick={()=>processUnauthAbsence(doc.id)}>삭제</button>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title attendmng">
                    <img src={AbsenteeIcon} alt='부재자관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                    <div className="notselected" onClick={toAbsenteeMng} >부재자관리</div>
                    <div className="notselected" style={{margin:'0rem 0.3rem'}}>·</div>
                    <div>무단부재자관리</div>
                </div>
                <div className="calendar_area"
                    onClick={(e)=>{ 
                        setCalendarPosition({ top: '17rem', left: '1.25rem' });
                        setShowCalendar(!showCalendar);
                }}>
                    <div className="calendar_text">{displayDate}</div>
                    <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                </div>
                <ViewCalendar />
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'3.12rem'}}>
                    <div className="btn_midside" 
                        onClick={() => {
                            if(sortCriteria === "seatno"){
                                handleSortClick('stdname')
                            }else{
                                handleSortClick('seatno')
                            }
                        }}
                    >
                        {sortCriteria === "seatno" ? "좌석순"
                        : "이름순"
                        }
                        <img src={ArrowUpDown} alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                    </div>
                    <div className="search_name_area">
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefMobile}/>
                        <div className="button_area">
                            <img src={SearchWhite} style={{width:'0.9375rem',height:'0.99613rem'}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefMobile.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="name">이름</div>
                        <div className="seat">좌석</div>
                        <div className="phone">전화번호</div>
                        <div className="phone">학부모 전화번호</div>
                        <div className="personalinfo">학교/학년/성별</div>
                        <div className="register_time">등록일시</div>
                    </div>
                    {attendData.map((doc) => {
                        const formatPhoneNumber = (phoneNumber) => {
                            const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                            const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                        
                            if (match) {
                            return match[1] + '-' + match[2] + '-' + match[3];
                            }
                        
                            return phoneNumber;
                        };

                        const student = studentData.filter(item => item.userphone === doc.stdphone);
                        const parentPhone = student[0].parentphone
                        const school = student[0].school;
                        const grade = student[0].grade;
                        const gender = student[0].gender;

                        let genderTxt;
                        
                        if(gender === "M"){
                            genderTxt = "남";
                        }else if(gender === "F"){
                            genderTxt = "여";
                        }else{
                            genderTxt = "";
                        }
                        const userInfo = school+" / "+grade+" / "+genderTxt;


                        const itemDate = doc.unauthregtime.toDate();
                        const itemYear = itemDate.getFullYear();
                        const itemMonth = itemDate.getMonth() + 1;
                        const itemDay = itemDate.getDate();
                        const itemHours = itemDate.getHours();
                        const itemMinutes = itemDate.getMinutes();

                        return (
                            <div className="body_area_mobile" key={doc.id}>
                                <div className="name">{doc.stdname}</div>
                                <div className="seat">
                                    <div className="seatno">{doc.seatno}</div>
                                </div>
                                <div className="phone">{formatPhoneNumber(doc.stdphone)}</div>
                                <div className="phone">{formatPhoneNumber(parentPhone)}</div>
                                <div className="personalinfo">{userInfo}</div>
                                <div className="register_time">{itemYear}/{itemMonth}/{itemDay} {itemHours}:{itemMinutes}</div>
                                <div className="unauthabsentee">
                                    <button className="unauthabsentee" style={{color:"#FF6F6F"}}  onClick={()=>processUnauthAbsence(doc.id)}>삭제</button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}

export default UnAuthAbsenteeMng;