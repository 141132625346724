import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, getDoc, doc, updateDoc, deleteDoc, where, orderBy } from "firebase/firestore";
import BtnSearch from '../images/buttons/search.png';
import BtnArrowDown from '../images/buttons/arrow_down_grey_16.png'
import Swal from "sweetalert2";
import PointIcon from '../images/buttons/point_icon_mobile.svg';
import Downward from '../images/buttons/downward_mobile.svg';
import ArrowUpDown from '../images/buttons/arrow_updown_mobile.svg';
import SearchWhite from '../images/buttons/search_white_mobile.svg';

function PointHistory() {
    const navigate = useNavigate();
    const searhNameRef = useRef(null);
    const searhNameRefTablet = useRef(null);
    const searhNameRefMobile = useRef(null);

    const [historyData, setHistoryData] = useState([]);
    const [historyDataRaw, setHistoryDataRaw] = useState([]);
    const [sortCriteria, setSortCriteria] = useState('regtime'); // 초기 정렬 기준은 좌석번호

    const [seasonInfo, setSeasonInfo] = useState([]);   //시즌 정보
    const [selSeason, setSelSeason] = useState("sd2");  //선택 시즌 변수

    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);

    //시즌 정보 코드
    useEffect(() => {
        async function fetchSeasonInfo() {
            const seasonQ = query(collection(db, "seasoninfo"), orderBy("seq", 'desc'));
            const seasonSnapshot = await getDocs(seasonQ);
    
            const seasonData = seasonSnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            setSeasonInfo(seasonData);
        }
    
        fetchSeasonInfo();
    }, []);

    const callDb = useCallback(async () => {
        const userq = query(collection(db, "users"), where("confirmyn", "==", "Y"), 
                        where("auth", "==", 1), where("groupid", "==", selSeason));
        const userSnapshot = await getDocs(userq); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
        const histq = query(collection(db, "userhistory"),where("groupid", "==", selSeason),
                    where("membertype", "==", "C"));
        const histsnapshot = await getDocs(histq); 

        const userData = userSnapshot.docs.concat(histsnapshot.docs).map(doc => {
            return {
                ...doc.data(),
                id: doc.id,
                totalpoint: doc.data().pointplus - doc.data().pointminus,
            };
        });
        
        //데이터 조회 변수
        const q = query(collection(db, "pointhistory"), where("groupid", "==", selSeason));
        const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

        const pointHistoryData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        
        // setHistoryData(pointHistoryData);
        // setHistoryDataRaw(pointHistoryData);

        // studentData의 seatno 필드 데이터를 pointHistoryData 추가
        const combinedData = pointHistoryData.map((point) => {
            const matchingStudent = userData.find((student) => student.userphone === point.userphone);
            return {
            ...point,
            seatno: matchingStudent ? matchingStudent.seatno : "",
            school: matchingStudent ? matchingStudent.school : "",
            grade: matchingStudent ? matchingStudent.grade : "",
            gender: matchingStudent ? matchingStudent.gender : "",
            };
        });
        setHistoryData(combinedData);
        setHistoryDataRaw(combinedData);
    }, [selSeason])

    useEffect(() => {
        callDb();
    }, [callDb, updateState])

    //이름조회 input 처리로직
    const handleSearch = (props) => {
        const nameValue = props;
        // 검색어가 비어있는 경우 모든 데이터를 보여줍니다.
        if(nameValue === ""){
            setHistoryData(historyDataRaw);
        } else {
            // 검색어가 있을 때 필터링된 데이터만 보여줍니다.
            const filteredCustomer = historyDataRaw.filter(item => item.username.includes(nameValue));
            setHistoryData(filteredCustomer);
        }
    }

    const pointDel = (id, userphone, pointplus, pointminus) => {
        if(selSeason === "sd2"){
            Swal.fire({
                icon: "question",
                title: "이력 삭제",
                text: "선택된 이력을 삭제하겠습니까?",
                showCancelButton: true,
                confirmButtonText: "삭제",
                cancelButtonText: "취소",
            }).then(async (res) => {
                if (res.isConfirmed) {
                    try{
                        const ref = doc(db, "users", userphone)
                        const docSnapshot = await getDoc(ref);
            
                        if (docSnapshot.exists()) {
                            const currentPointplus = docSnapshot.data().pointplus;
                            const currentPointminus = docSnapshot.data().pointminus;
                            const updatedPointplus = currentPointplus - pointplus;
                            const updatedPointminus = currentPointminus - pointminus;
            
                            await updateDoc(ref, {
                                pointplus: updatedPointplus,
                                pointminus: updatedPointminus,
                            });
                        }
            
                        await deleteDoc(doc(db, "pointhistory", id));
                        Swal.fire({
                            icon: "success",
                            title: "이력 삭제",
                            text: "이력 삭제가 완료되었습니다",
                            //showCancelButton: true,
                            confirmButtonText: "확인",
                            //cancelButtonText: "취소",
                        }).then((res) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (res.isConfirmed) {
                                setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                            }
                        });
                    }catch(error){
                        console.log(error.message);
                    }
                }
            });
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    const toPointMng = () => {
        navigate("/pointmng")
    }

    const handleSortClick = (criteria) => {
        setSortCriteria(criteria);
    };

    return (
        <>
            <div className="content_wrap">
                <div style={{width:"100%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem",marginBottom:"3.125rem"}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toPointMng}
                        >상벌점관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent">상벌점이력</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRef}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRef.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"100%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex'}}>
                    <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                            onChange={(e) => {
                                setSelSeason(e.target.value);
                            }}>
                        {seasonInfo.map(season => (
                            <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                        ))}
                    </select>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('regtime')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='등록순' style={{width:"1rem",height:"1rem"}}/>
                        등록순
                    </div>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('username')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{width:"6.25rem"}}>이름</div>
                    <div style={{width:"6.25rem"}}>좌석번호</div>
                    <div style={{width:"12.5rem"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"9.375rem",textAlign:'center'}}>상점</div>
                    <div style={{width:"9.375rem",textAlign:'center'}}>벌점</div>
                    <div style={{width:"25rem",textAlign:'center'}}>비고</div>
                    <div style={{width:"12.5rem",textAlign:'center'}}>등록일시</div>
                    <div style={{width:"6.25rem"}}/>
                </div>
                {historyData
                    .sort((a, b) => {
                        if(sortCriteria === 'regtime') {
                            return b.regtime - a.regtime; // 숫자로 비교
                        } else if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'username') {
                            // 이름을 문자열로 비교
                            return a.username.localeCompare(b.username);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        const filteredStudent = historyData.filter(item => item.userphone.includes(doc.userphone));
                        let gender;

                        if(filteredStudent[0].gender === "M"){
                            gender = "남";
                        }else if(filteredStudent[0].gender === "F"){
                            gender = "여";
                        }else{
                            gender = "";
                        }
                        
                        const userInfo = filteredStudent[0].school+" / "+filteredStudent[0].grade+" / "+gender;

                        return(
                            <div key={doc.id} style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                                <div style={{width:"6.25rem",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"6.25rem"}}>{doc.seatno}</div>
                                <div style={{width:"12.5rem"}}>{userInfo}</div>
                                <div style={{width:"9.375rem",textAlign:'center'}}>{doc.pointplus}</div>
                                <div style={{width:"9.375rem",textAlign:'center',color:"#FF5B5B"}}>{doc.pointminus}</div>
                                <div style={{width:"25rem",textAlign:'center'}}>{doc.comment}</div>
                                <div style={{width:"12.5rem",textAlign:'center'}}>{doc.regtimedisplay}</div>
                                <div style={{width:"6.25rem",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#FF594F",width:"5.313rem"}} onClick={()=>pointDel(doc.id, doc.userphone, doc.pointplus, doc.pointminus)}>삭제</button>
                                </div>
                            </div>
                        )
                    })
                }
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem",marginBottom:"3.125rem"}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toPointMng}
                        >상벌점관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent">상벌점이력</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefTablet}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRefTablet.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex'}}>
                    <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                            onChange={(e) => {
                                setSelSeason(e.target.value);
                            }}>
                        {seasonInfo.map(season => (
                            <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                        ))}
                    </select>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('regtime')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='등록순' style={{width:"1rem",height:"1rem"}}/>
                        등록순
                    </div>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('username')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"95%",height:"3rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"1rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                    {/* <div style={{width:"120px"}}>이름</div>
                    <div style={{width:"200px"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"100px",textAlign:'center'}}>상점</div>
                    <div style={{width:"100px",textAlign:'center'}}>벌점</div>
                    <div style={{width:"200px",textAlign:'center'}}>비고</div>
                    <div style={{width:"200px",textAlign:'center'}}>등록일시</div>
                    <div style={{width:"100px"}}/> */}
                    <div style={{width:"10%"}}>이름</div>
                    <div style={{width:"9%"}}>좌석번호</div>
                    <div style={{width:"18%"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"7%",textAlign:'center'}}>상점</div>
                    <div style={{width:"7%",textAlign:'center'}}>벌점</div>
                    <div style={{width:"20%",textAlign:'center'}}>비고</div>
                    <div style={{width:"20%",textAlign:'center'}}>등록일시</div>
                    <div style={{width:"9%"}}/>
                </div>
                {historyData
                    .sort((a, b) => {
                        if (sortCriteria === 'totalpoint') {
                            return b.totalpoint - a.totalpoint; // 숫자로 비교
                        } else if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'username') {
                            // 이름을 문자열로 비교
                            return a.username.localeCompare(b.username);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        const filteredStudent = historyData.filter(item => item.userphone.includes(doc.userphone));
                        let gender;

                        if(filteredStudent[0].gender === "M"){
                            gender = "남";
                        }else if(filteredStudent[0].gender === "F"){
                            gender = "여";
                        }else{
                            gender = "";
                        }
                        
                        const userInfo = filteredStudent[0].school+" / "+filteredStudent[0].grade+" / "+gender;
                        // const seatNo = filteredStudent[0].seatno;

                        return(
                            <div key={doc.id} style={{width:"95%",height:"3rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,fontSize:"0.875rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                                {/* <div style={{width:"120px",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"200px"}}>{userInfo}</div>
                                <div style={{width:"100px",textAlign:'center'}}>{doc.pointplus}</div>
                                <div style={{width:"100px",textAlign:'center',color:"#FF5B5B"}}>{doc.pointminus}</div>
                                <div style={{width:"200px",textAlign:'center'}}>{doc.comment}</div>
                                <div style={{width:"200px",textAlign:'center'}}>{doc.regtimedisplay}</div>
                                <div style={{width:"100px",display:'flex',alignItems:'center',justifyContent:'flex-end'}}> */}
                                <div style={{width:"10%",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"9%"}}>{doc.seatno}</div>
                                <div style={{width:"18%"}}>{userInfo}</div>
                                <div style={{width:"7%",textAlign:'center'}}>{doc.pointplus}</div>
                                <div style={{width:"7%",textAlign:'center',color:"#FF5B5B"}}>{doc.pointminus}</div>
                                <div style={{width:"20%",textAlign:'center'}}>{doc.comment}</div>
                                <div style={{width:"20%",textAlign:'center'}}>{doc.regtimedisplay}</div>
                                <div style={{width:"9%",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#FF594F",width:"85px"}} onClick={()=>pointDel(doc.id, doc.userphone, doc.pointplus, doc.pointminus)}>삭제</button>
                                </div>
                            </div>
                        )
                    })
                }
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title attendmng">
                    <img src={PointIcon} alt='상벌점관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                    <div className="notselected" onClick={toPointMng}>상벌점관리</div>
                    <div className="notselected" style={{margin:'0rem 0.3rem'}}>·</div>
                    <div>상벌점이력</div>
                </div>
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'3.13rem'}}>
                    <div style={{display:'flex'}}>
                        <div className="button_season_sort">
                            <select className="season" 
                                onChange={(e) => {setSelSeason(e.target.value);}}>
                                {seasonInfo.map(season => (
                                    <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                                ))}
                            </select>
                            <img src={Downward} alt='시즌' style={{width:"1rem",height:"1rem"}} />
                        </div>
                        <div style={{width:'0.62rem'}} />
                        <div className="button_season_sort" 
                            onClick={() => {
                                if(sortCriteria === "seatno"){
                                    handleSortClick('username')
                                }else{
                                    handleSortClick('seatno')
                                }
                            }}
                        >
                            {sortCriteria === "seatno" ? "좌석순"
                            : "이름순"
                            }
                            <img src={ArrowUpDown} alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        </div>
                    </div>
                    <div className="search_name_area">
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefMobile}/>
                        <div className="button_area">
                            <img src={SearchWhite} style={{width:'0.9375rem',height:'0.99613rem'}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefMobile.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="name">이름</div>
                        <div className="seat">좌석</div>
                        <div className="personalinfo">학교/학년/성별</div>
                        <div className="point">상점</div>
                        <div className="point">벌점</div>
                        <div className="comment">비고</div>
                        <div className="personalinfo">등록일시</div>
                    </div>
                    {historyData
                        .sort((a, b) => {
                            if (sortCriteria === 'totalpoint') {
                                return b.totalpoint - a.totalpoint; // 숫자로 비교
                            } else if (sortCriteria === 'seatno') {
                                // 좌석번호 숫자를 비교
                                if (a.seatno !== "" && b.seatno !== "") {
                                    return a.seatno - b.seatno;
                                } else if (a.seatno === "") {
                                    return 1; // null은 뒤로 정렬
                                } else {
                                    return -1; // null은 뒤로 정렬
                                }
                            } else if (sortCriteria === 'username') {
                                // 이름을 문자열로 비교
                                return a.username.localeCompare(b.username);
                            }
                            // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                            return 0;
                        })
                        .map((doc) => {
                            const filteredStudent = historyData.filter(item => item.userphone.includes(doc.userphone));
                            let gender;

                            if(filteredStudent[0].gender === "M"){
                                gender = "남";
                            }else if(filteredStudent[0].gender === "F"){
                                gender = "여";
                            }else{
                                gender = "";
                            }
                            
                            const userInfo = filteredStudent[0].school+" / "+filteredStudent[0].grade+" / "+gender;
                            // const seatNo = filteredStudent[0].seatno;

                            return(
                                <div className="body_area_mobile" key={doc.id}>
                                    <div className="name">{doc.username}</div>
                                    <div className="seat">
                                        <div className="seatno">{doc.seatno}</div>
                                    </div>
                                    <div className="personalinfo">{userInfo}</div>
                                    <div className="point plus">{doc.pointplus}</div>
                                    <div className="point minus">{doc.pointminus}</div>
                                    <div className="comment">{doc.comment}</div>
                                    <div className="personalinfo">{doc.regtimedisplay}</div>
                                    <div className="unauthabsentee">
                                        <button className="unauthabsentee" style={{color:"#FF6F6F"}}  onClick={()=>pointDel(doc.id, doc.userphone, doc.pointplus, doc.pointminus)}>삭제</button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default PointHistory;