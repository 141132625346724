import React, { useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { Outlet, Navigate, useNavigate } from 'react-router-dom';

function PrivateRoute(props) {
    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate();

    useEffect(() => {
        if (props.authentication === "true") {
            if (user === null) {
                navigate('/login');
            }
        } else {
            if (user !== null) {
                navigate('/');
            }
        }
    }, [props.authentication, user, navigate]);

    if (props.authentication === "true" && user === null) {
        return <Navigate to="/login" />;
    } else if (props.authentication === "false" && user !== null) {
        return <Navigate to="/" />;
    } else {
        return <Outlet />;
    }
}

export default PrivateRoute