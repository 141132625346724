import React from 'react';
import { db } from '../firebase_config.js';
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import 'react-calendar/dist/Calendar.css';
import AlmMobile from '../images/buttons/alarm_mobile.png';
import Swal from "sweetalert2";

function AdminPage() {
    const fieldAdd = async () => {
        try{     
            // const now = new Date();
            const collectionRef = collection(db, 'users');
            const snapshot = await getDocs(collectionRef);

            snapshot.forEach(async (docSnapshot) => {
                // 각 문서에 필드 추가
                const docRef = doc(db, "users", docSnapshot.id);
                await updateDoc(docRef, {
                    // uploadtime: now
                    groupid: "sd"
                });
            });

            Swal.fire({
                icon: "success",
                title: "필드등록완료",
                text: "필드등록완료",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        } catch(error) {
            console.log("에러메세지:", error.message);
        }
    }

    return (
        <>
            <div className="content_wrap">
                <div style={{width:'100%',marginTop:"20px",marginBottom:"100px"}}>
                    <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}>관리자페이지</div>
                </div>
                <div style={{width:"100%",height:"40px",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"15px",borderBottom:"#EEEEEE 1px solid",lineHeight:"40px"}}>
                    <div style={{width:"200px"}} onClick={()=>{
                        // fieldAdd();
                    }}>업데이트</div>
                </div>
                <footer>
                    <div className="copyright" style={{marginTop:"50px",marginBottom:"50px"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",marginTop:"20px",marginBottom:"100px"}}>
                    <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}>관리자페이지</div>
                </div>
                <div style={{width:"95%",height:"48px",display:'flex',color:"#A6A7AA",fontWeight:400,fontSize:"16px",borderBottom:"#EEEEEE 1px solid",lineHeight:"48px"}}>
                    <div style={{width:"10%"}} onClick={()=>{
                        // fieldAdd();
                    }}>업데이트</div>
                </div>
                <footer>
                    <div className="copyright" style={{marginTop:"50px",marginBottom:"50px"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile">
                <div className="no_provide">
                    <img src={AlmMobile} style={{width:"64px",height:"64px",marginBottom:"30px"}} alt='알림' />
                    <div>스마트폰에서</div>
                    <div>지원하지 않는 화면입니다.</div>
                </div>
            </div>
        </>
    );
}

export default AdminPage;